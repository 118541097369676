import { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import {db} from './../../firebase';
import Divider from '@mui/material/Divider';
// Components
import StandingsModal from "../components/standingsModal";
// Styling
import './../../styles/standings.scss';

function Standings() {
    const [divisions, setDivisions] = useState([]);
    const [teams, setTeams] = useState([]);
    
    // Retrieve divisions and teams from firestore database
    useEffect(() => {
        const getDivisions = async () => {
            const divisionsCollectionRef = collection(db, "divisions");
            const data = await getDocs(divisionsCollectionRef);
            setDivisions(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        const getTeams = async () => {
            const teamCollectionRef = collection(db, "teams");
            const data = await getDocs(query(teamCollectionRef,orderBy("wins", "desc"), orderBy("points", "desc")));
            setTeams(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getDivisions();
        getTeams();
    }, []);

    return (
        <div className='standings-page'>
            <h1 className='page-title'>Standings</h1>

            <div className='select-prompt'>
                {divisions.length === 0 || teams.length === 0 ?<></> : <p>Select a division</p>}
                <Divider />
            </div>

            <div className='divisions'>
                {divisions.length === 0 || teams.length === 0 ? <h2 className='no-standings'>No standings at the moment, check back soon.</h2> :
                divisions.map((division) => (
                    <StandingsModal
                        key={division.id}
                        division={division}
                        teams={teams} />
                ))}
            </div>
        </div>
    )
}

export default Standings;