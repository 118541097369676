import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useLocation } from 'react-router-dom';
// Firebase imports
import { collection, doc, getDocs, addDoc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './../../../firebase';
// Bootstrap imports
import Alert from 'react-bootstrap/Alert';
// Datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// Moment
import moment from 'moment';

function EditGame() {
        // Verify that the user is authenticated to use this page
        const [user, loading, error] = useAuthState(auth);
        const navigate = useNavigate();
    
        useEffect(() => {
            if (loading) {
                return;
            }
            if (!user) {
                return navigate("/login");
            }
        }, [user, loading]);

    // Game variables
    const [newGame, setNewGame] = useState('');
    const [newDivision, setNewDivision] = useState('');
    const [newTeamOne, setNewTeamOne] = useState('');
    const [newTeamTwo, setNewTeamTwo] = useState('');
    const [newGym, setNewGym] = useState('');
    const [newDate, setNewDate] = useState(new moment());
    const [gameComplete, setGameComplete] = useState(false);
    // Complete game variables
    const [newTeamOneScore, setNewTeamOneScore] = useState(0);
    const [newTeamTwoScore, setNewTeamTwoScore] = useState(0);

    // Variable to check if a division was loaded
    //      - Used to load the rest of the page
    // const [loadForm, setLoadForm] = useState(false);

    // Division variables
    const [divisions, setDivisions] = useState([]);
    useEffect(() => {

        const getDivisions = async () => {
            const divisionsCollectionRef = collection(db, "divisions");
            const data = await getDocs(divisionsCollectionRef);
            setDivisions(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getDivisions();
    }, []);

    // Team variables
    const [teams, setTeams] = useState([]);
    useEffect(() => {

        const getTeams = async () => {
            const teamsCollectionRef = collection(db, "teams");
            const data = await getDocs(teamsCollectionRef);
            setTeams(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getTeams();
    }, []);

    // Gym variables
    const [gyms, setGyms] = useState([]);
    useEffect(() => {

        const getGyms = async () => {
            const gymsCollectionRef = collection(db, "gyms");
            const data = await getDocs(gymsCollectionRef);
            setGyms(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getGyms();
    }, []);

    const location = useLocation();
    let game;

    // anonymous function useEffect to prevent infinite looping of setState
    //      - eg. if I try to setNewGame to game.name outside of useEffect,
    //      an infinite loop occurs
    useEffect(() => {
        if (location.state !== null) {
            setNewGame(location.state.id);
            setNewDivision(location.state.division);
            setNewTeamOne(location.state.teamone);
            setNewTeamTwo(location.state.teamtwo);
            setNewGym(location.state.gym);
            setNewDate(moment(location.state.datetime));
            // If the game has been completed, populate the scores
            setGameComplete(location.state.gamecomplete);
            if (location.state.gamecomplete !== undefined) {
                setNewTeamOneScore(location.state.teamonescore);
                setNewTeamTwoScore(location.state.teamtwoscore);
            }
        }
    }, [])

    // Alert visibility states
    const [saveAlertState, setSaveAlertState] = useState(false);
    const [saveWarningAlertState, setSaveWarningAlertState] = useState(false);
    const [deleteAlertState, setDeleteAlertState] = useState(false);
    const [deleteWarningAlertState, setDeleteWarningAlertState] = useState(false);

    // Clear all fields
    const clearFields = () => {
        setGameComplete(false);
        setNewGame('');
        setNewDivision('');
        setNewTeamOne('');
        setNewTeamTwo('');
        setNewGym('');
        setNewDate(new moment());
        setNewTeamOneScore(0);
        setNewTeamTwoScore(0);

        setSaveAlertState(false);
        setSaveWarningAlertState(false);
        setDeleteAlertState(false);
        setDeleteWarningAlertState(false);

        window.scrollTo(0,0);
    }

    // Click event for when user wants to save their game
    const saveGame = async () => {
        // Check if user has input a division and two teams before saving
        if (newDivision !== '' && newTeamOne !== '' && newTeamTwo !== '' && newGym !== '') {
            
            // If game already exists, update current existing team. Otherwise,
            // create new game

            if (newGame === '') {
                const docRef = await addDoc(collection(db, "games"), {
                    teamone: newTeamOne,
                    teamtwo: newTeamTwo,
                    division: newDivision,
                    gym: newGym,
                    datetime: newDate.format(),
                    gamecomplete: gameComplete,
                    teamonescore: (isNaN(newTeamOneScore) ? 0 : parseInt(newTeamOneScore)),
                    teamtwoscore: (isNaN(newTeamTwoScore) ? 0 : parseInt(newTeamTwoScore)),
                });
            }
            else {
                await setDoc(doc(db, "games", newGame), {
                    teamone: newTeamOne,
                    teamtwo: newTeamTwo,
                    division: newDivision,
                    gym: newGym,
                    datetime: newDate.format(),
                    gamecomplete: gameComplete,
                    teamonescore: (isNaN(newTeamOneScore) ? 0 : parseInt(newTeamOneScore)),
                    teamtwoscore: (isNaN(newTeamTwoScore) ? 0 : parseInt(newTeamTwoScore)),
                })
            }

            clearFields();
            setDeleteAlertState(false);
            setDeleteWarningAlertState(false);
            setSaveWarningAlertState(false);
            setSaveAlertState(true);
        }
        else {
            setDeleteAlertState(false);
            setDeleteWarningAlertState(false);
            setSaveAlertState(false);
            setSaveWarningAlertState(true);
        }

        window.scrollTo(0,0);
    }

    // Click event for when user wants to delete the game
    const deleteGame = async () => {
        if (newGame !== '') {
            // Find specified game in Firestore
            const docRef = doc(db, "games", newGame);
            const docSnap = await getDoc(docRef);

            // If game exists, delete it and tell user
            if (docSnap.exists()) {
                await deleteDoc(doc(db, "games", newGame));
                clearFields();
                setSaveAlertState(false);
                setSaveWarningAlertState(false);
                setDeleteWarningAlertState(false);
                setDeleteAlertState(true);
            }
            // If game DOES NOT exist, do not delete, warn the user it does not exist
            else {
                setSaveAlertState(false);
                setSaveWarningAlertState(false);
                setDeleteAlertState(false);
                setDeleteWarningAlertState(true);
            }
        }
        else {
            alert('Cannot delete a game that does not exist');
        }

        window.scrollTo(0,0);
    }

    return (
        <div className='edit edit-game-page'>
            <Alert variant='success' show={saveAlertState}>Game saved successfully.</Alert>
            <Alert variant='warning' show={saveWarningAlertState}>Save unsuccessful, one or more required fields are missing.</Alert>
            <Alert variant='danger' show={deleteAlertState}>Game deleted successfully.</Alert>
            <Alert variant='warning' show={deleteWarningAlertState}>Game does not exist.</Alert>

            <h1 className='page-title'>Edit Game</h1>
            
            <Link className="management-link" to='/manage-games'>Back to Game Management Page</Link>

            {/* CRUD functionality*/}
            <div>
                <div className='user-input division-section'>
                    <label>Division</label>
                    <select
                        value={newDivision}
                        disabled={gameComplete}
                        onChange={(e) => {setNewDivision(e.target.value)}}>
                        <option value="">Select a division</option>
                        {divisions.map((division) => {
                            return (
                                <option
                                key={division.name}
                                value={division.name}>
                                    {division.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
                

                {/* If a division has been entered, provide the user with
                    the rest of the form */}
                {newDivision === '' ? <></> :
                <>
                    {/* Team selections */}
                    <div className='user-input team-one-section'>
                        <label>Team 1</label>
                        <select
                            value={newTeamOne}
                            disabled={gameComplete}
                            onChange={(e) => {setNewTeamOne(e.target.value)}}>
                            <option value="">Enter a team</option>
                            {teams.map((team) => {
                                if (team.division === newDivision) {
                                    return (
                                        <option
                                        key={team.name}
                                        value={team.name}>
                                            {team.name}
                                        </option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    
                    <div className='user-input team-two-section'>
                        <label>Team 2</label>
                        <select
                            value={newTeamTwo}
                            disabled={gameComplete}
                            onChange={(e) => {setNewTeamTwo(e.target.value)}}>
                            <option value="">Enter a team</option>
                            {teams.map((team) => {
                                if (team.division === newDivision) {
                                    return (
                                        <option
                                        key={team.name}
                                        value={team.name}>
                                            {team.name}
                                        </option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    
                    <div className='user-input gym-section'>
                        <label>Gym</label>
                        <select
                            value={newGym}
                            disabled={gameComplete}
                            onChange={(e) => {setNewGym(e.target.value)}}>
                            <option value="">Enter a gym</option>
                            {gyms.map((gym) => {
                                return (
                                    <option
                                    key={gym.name}
                                    value={gym.name}>
                                        {gym.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    
                    <div className='user-input date-section'>
                        <label>Date</label>
                        <DatePicker
                            className='date-select'
                            selected={newDate.toDate()}
                            disabled={gameComplete}
                            onChange={(date) => setNewDate(moment(date))}
                            showTimeSelect
                            timeIntervals={20}
                            dateFormat="MMMM d, yyyy hh:mm aa"
                            popperPlacement='top'
                        />
                    </div>

                    <div className='user-input complete-section'>
                        <label >Game complete?</label>
                        <input type="checkbox" name="gameComplete" checked={gameComplete} value={gameComplete} onChange={() => {setGameComplete(!gameComplete)}}/>
                    </div>

                    {!gameComplete ?
                        <></> :
                        <>
                            <div className='user-input team-one-score-section'>
                                <label>{newTeamOne} Score</label>
                                <input
                                    type="number"
                                    placeholder="teamOneScore"
                                    value={newTeamOneScore}
                                    onChange={(e) => {setNewTeamOneScore(e.target.value)}}>
                                </input>
                            </div>
                            
                            <div className='user-input team-two-score-section'>
                                <label>{newTeamTwo} Score</label>
                                <input
                                    type="number"
                                    placeholder="teamTwoScore"
                                    value={newTeamTwoScore}
                                    onChange={(e) => {setNewTeamTwoScore(e.target.value)}}>
                                </input>
                            </div>
                        </>
                    }
                </>}
                
                <div className='button-container'>
                    <button className='save-button' onClick={saveGame}>Save</button>
                    <button className='clear-button' onClick={clearFields}>Clear</button>
                    <button className='delete-button' onClick={deleteGame}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default EditGame;