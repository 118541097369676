import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
// Authentication imports
import { auth, db, storage } from "../../firebase";
// Firebase
import { collection, query, orderBy, limit, getDocs, where } from "firebase/firestore";
import { listAll, getDownloadURL, ref } from "firebase/storage";
// Components
import AnnouncementCard from "../components/announcementCard";
import GameDisplay from "../components/gameDisplay";
import PlayerCarousel from '../components/playerCarousel';
// Logo imports
import { ReactComponent as PSALogo } from './../../assets/icons/psa_logo_full.svg';
import { ReactComponent as CrosstownLogo } from './../../assets/icons/sponsor-crosstown-logo.svg';
import { ReactComponent as CavanaghDentalLogo } from './../../assets/icons/sponsor-cavanagh-dental-logo.svg';
import { ReactComponent as ChangesForHopeLogo } from './../../assets/icons/sponsor-changes-for-hope-logo.svg';
import { ReactComponent as WhitemudPhysioLogo } from './../../assets/icons/sponsor-whitemud-physio-logo.svg';
import { ReactComponent as ZenZoneClinicLogo } from './../../assets/icons/zen-zone-clinic.svg';
// Styling
import './../../styles/home.scss';
// Temp image import
import splashImage from '../../assets/images/splash-info.jpg'

function Home() {
  const [homeAnnouncements, setHomeAnnouncements] = useState([]);
  const [homeSchedule, setHomeSchedule] = useState([]);
  const [homeResults, setHomeResults] = useState([]);
  const [homePlayerImageList, setHomePlayerImageList] = useState([]);

  useEffect(() => {
    const getAnnouncements = async () => {
      const announcementCollectionRef = collection(db, "announcements");
      const data = await getDocs(query(announcementCollectionRef, orderBy("datepublished", "desc"), limit(2)));
      setHomeAnnouncements(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const getSchedule = async () => {
      const gameCollectionRef = collection(db, "games");
      const data = await getDocs(query(gameCollectionRef, where("gamecomplete", "==", false), orderBy("datetime"), limit(3)));
      setHomeSchedule(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const getResults = async () => {
      const gameCollectionRef = collection(db, "games");
      const data = await getDocs(query(gameCollectionRef, where("gamecomplete", "==", true), orderBy("datetime", "desc"), limit(3)));
      setHomeResults(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
    }

    const getPOTW = async () => {
      const playerImageListRef = ref(storage, "potw/");
      listAll(playerImageListRef).then((response) => {
        response.items.forEach((image) => {
            getDownloadURL(image).then((url) => {
                setHomePlayerImageList((current) => [...current, url])
            });
        });
    });
    }

    getAnnouncements();
    getSchedule();
    getResults();
    getPOTW();

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      // Create 4 duplicates of the sponsors for infinite scrolling purposes
      for (let duplicates = 0; duplicates < 4; duplicates++) {
        duplicateScrollItems();
      }
    }
  }, [])

  function duplicateScrollItems () {
    let scrollers = document.querySelectorAll('.scroller');

      scrollers.forEach(scroller => {
        scroller.setAttribute('data-animated', true);
  
        let scrollerInner = scroller.querySelector('.scroller-inner');
        let scrollerContent = Array.from(scrollerInner.children);
  
        scrollerContent.forEach(item => {
          let duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute('aria-hidden', true);
          scrollerInner.appendChild(duplicatedItem);
        })
      });
  }

  return (
      <div className="home-page">
        <div className="hero-section">
          <div className="hero-container">
            <PSALogo className="psa-logo" />
            <div className='sponsor-banner'>
              <h2>Sponsored by</h2>
              <div className='scroller'>
                <ul className='scroller-inner'>
                  <CrosstownLogo className='sponsor-logo' />
                  <CavanaghDentalLogo className='sponsor-logo' />
                  <ChangesForHopeLogo className='sponsor-logo' />
                  <WhitemudPhysioLogo className='sponsor-logo' />
                  <ZenZoneClinicLogo className='sponsor-logo' />
                </ul>
              </div>
              <Link to='/sponsors'>View all sponsors</Link>
            </div>
          </div>
        </div>
        <div className='home-body'>

          {/* Temp info picture */}
          <div className="splash-section">
            <img src={splashImage} alt='spring/summer season details' title='Spring/Summer season details' />
          </div>

          {/* Announcements */}
          <div className='announcements-section'>
            <h2 className='home-title'>Announcements</h2>
            <div className='read-more-container'>
              <Link to={'/announcements'} className='view-all-link'>Read all</Link>
            </div>
            {/* Check to see if announcements exist */}
            {homeAnnouncements.length === 0 ? <h3>No announcements at the moment!</h3> : 
            <div className="announcement-items">
            {homeAnnouncements.map((announcement) => {
              return (
                <div className="home-announcement" key={announcement.id}>
                  {announcement.dateupdated === undefined ? 
                    <AnnouncementCard
                        announcement={announcement}
                        title={announcement.title}
                        body={announcement.body}
                        datepublished={announcement.datepublished}/> : 
                    <AnnouncementCard
                        announcement={announcement}
                        title={announcement.title}
                        body={announcement.body}
                        datepublished={announcement.datepublished}
                        dateupdated={announcement.dateupdated}/>
                    }
                  </div>
                )
              })}
            </div>}
          </div>
          
          {/* Only render player of the week carousel if there is one or more potw images uploaded */}
          {homePlayerImageList.length < 1 ? <></> :
            <div className="potw-section">
                <h2 className="home-title">Player of the Week</h2>
                <PlayerCarousel imageList={homePlayerImageList} />
            </div>
          }
          

          <div className="games-section">
            <div className="schedule-section">
              <div className="schedule-redirect">
                <h2 className='home-title'>Schedule</h2>
                <Link to={'/games'} className='view-all-link'>View all upcoming games</Link>
              </div>
              <GameDisplay games={homeSchedule} completed={false}/>
            </div>

            <div className="results-section">
              <div className="results-redirect">
                <h2 className='home-title'>Results</h2>
                <Link to={'/games'} className='view-all-link'>View all results</Link>
              </div>
              <GameDisplay games={homeResults} completed={true}/>
            </div>
          </div>

          <div className='register-prompt-section'>
            <h3>Want to play in the PSA?</h3>
            <Link to={'/register'} className='register-link'>Register now!</Link>
          </div>
        </div>
      </div>
  )
}

export default Home;