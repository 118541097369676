import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../firebase';
import { collection, doc, deleteDoc, setDoc, addDoc, getDocs } from 'firebase/firestore';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
// Bootstrap imports
import Alert from 'react-bootstrap/Alert';
// Styling
import '../../../styles/edit.scss'

function EditRegistration() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    const [registrationId, setRegistrationId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [season, setSeason] = useState('');
    const [players, setPlayers] = useState([]);
    const [hasMinors, setHasMinors] = useState(false);
    const [saveAlertState, setSaveAlertState] = useState(false);
    const [approveAlertState, setApproveAlertState] = useState(false);
    const [seasons, setSeasons] = useState([]);
    const [playerCount, setPlayerCount] = useState(0);
    const [deletePlayerActive, setDeletePlayerActive] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            setRegistrationId(location.state.id);
            setTeamName(location.state.teamname);
            setSeason(location.state.season);
            setPlayers(location.state.players);
            setHasMinors(location.state.hasminors);
            setPlayerCount(location.state.players.length);
        }

        // Retrieve seasons
        const getSeasons = async () => {
            const seasonCollectionRef = collection(db, "seasons");
            const data = await getDocs(seasonCollectionRef);
            setSeasons(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getSeasons();
    }, []);

    const deleteRegistration = async () => {
        await deleteDoc(doc(db, "registrations", location.state.id));
        navigate('/manage-registrations');
    }

    function editPlayerFirstName(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].firstname = value;
        setPlayers(tempPlayers);
    }

    function editPlayerLastName(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].lastname = value;
        setPlayers(tempPlayers);
    }

    function editPlayerEmail(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].email = value;
        setPlayers(tempPlayers);
    }

    function editPlayerPhone(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].phone = value;
        setPlayers(tempPlayers);
    }

    function editPlayerBirthdate(index, value) {
        console.log(moment(value).format());
        let tempPlayers = [...players];
        tempPlayers[index].birthday = moment(value).format();
        setPlayers(tempPlayers);
    }

    function addPlayer() {
        if (playerCount < 15) {
            setPlayers([...players, {
                birthday: moment(),
                firstname: '',
                lastname: '',
                leader: false,
                minor: true,
                phone: '',
            }]);
            setPlayerCount(playerCount + 1);
        }
    }

    function removePlayer(index) {
        setPlayers([
            ...players.slice(0, index),
            ...players.slice(index + 1),
        ]);
        setPlayerCount(playerCount - 1);
    }

    const saveRegistration = async () => {
        await setDoc(doc(db, "registrations", registrationId), {
            teamname: teamName,
            season: season,
            players: players,
            hasminors: hasMinors,
        });
        setSaveAlertState(true);

        window.scrollTo(0,0);
    }

    const approveRegistration = async () => {
        const docRef = await addDoc(collection(db, "teams"), {
            name: teamName,
            hasminors: hasMinors,
            division: "No division yet",
            gamesplayed: 0,
            wins: 0,
            losses: 0,
            pf: 0,
            pa: 0,
            gd: 0,
            points: 0,
            players: players,
        });
        setApproveAlertState(true);
        deleteRegistration();

        window.scrollTo(0,0);
    }

    return (
        <div className="edit edit-registrations-page">
            <Alert variant='success' show={approveAlertState}>Registration approved.</Alert>
            <Alert variant='success' show={saveAlertState}>Registration saved successfully.</Alert>

            <h1 className='page-title'>Edit Registration</h1>

            <Link className='management-link' to='/manage-registrations'>Back to Registration Management Page</Link>


            {/* CRUD Functionality */}
            <div className='team-info'>
                <div className='user-input name-section'>
                    <label>Team Name</label>
                    <input
                        placeholder='Team name'
                        value={teamName}
                        onChange={(e) => {setTeamName(e.target.value)}}>
                    </input>
                </div>

                <div className='user-input registration-season-section'>
                    <label>Season</label>
                    <select
                        value={season}
                        onChange={(e) => {setSeason(e.target.value)}}>
                        <option value=''>Select a season</option>
                        {seasons.map((season) => {
                            return (
                                <option
                                    key={season.name}
                                    value={season.name}>
                                    {season.name}
                                </option>
                            )
                        })}
                    </select>
                </div>

                <div className='user-input players-section'>
                    {/* Leader */}
                    {players.map((player, playerIndex) => {
                        return (
                            <div className='leader-inputs' key={`player${playerIndex}`}>
                                {player.leader ?
                                    <div className='player-inputs'>
                                        <label className='leader-title'>Leader</label>
                                        <div className='player-name'>
                                            <input
                                                placeholder='First Name'
                                                value={player.firstname}
                                                onChange={(e) => {
                                                    editPlayerFirstName(playerIndex, e.target.value);
                                                }}>
                                            </input>
                                            <input
                                                placeholder='Last Name'
                                                value={player.lastname}
                                                onChange={(e) => {
                                                    editPlayerLastName(playerIndex, e.target.value);
                                                }}>
                                            </input>
                                        </div>

                                        {player.email ?
                                            <div className='email-input-section'>
                                                <label>Email</label>
                                                <input
                                                    placeholder='Email'
                                                    value={player.email}
                                                    onChange={(e) => {
                                                        editPlayerEmail(playerIndex, e.target.value)
                                                    }}
                                                >
                                                </input>
                                            </div> : <></>}
                                        
                                        <div className='phone-input-section'>
                                            <label>Phone</label>
                                            <input
                                                placeholder='Phone'
                                                value={player.phone}
                                                onChange={(e) => {
                                                    editPlayerPhone(playerIndex, e.target.value)
                                                }}>
                                            </input>
                                        </div>

                                        <div className='birthday-input-section'>
                                            <label>Birthday</label>
                                            <DatePicker
                                            className='birthdate-input'
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={90}
                                            maxDate={moment().toDate()}
                                            selected={moment(player.birthday).toDate()}
                                            onChange={(date) => {
                                                editPlayerBirthdate(playerIndex, date);
                                            }}
                                            dateFormat="MMMM d, yyyy"
                                            popperPlacement='top'
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        )
                    })}
                    
                    <label className='players-title'>Players</label>

                    {players.map((player, playerIndex) => {
                        return (
                            <div key={`player${playerIndex}`}>
                                {!player.leader ?
                                    <div className='player-inputs'>
                                        <label>Name</label>
                                        <div className='player-name'>
                                            <input
                                                placeholder='First Name'
                                                value={player.firstname}
                                                onChange={(e) => {
                                                    editPlayerFirstName(playerIndex, e.target.value);
                                                }}>
                                            </input>
                                            <input
                                                placeholder='Last Name'
                                                value={player.lastname}
                                                onChange={(e) => {
                                                    editPlayerLastName(playerIndex, e.target.value);
                                                }}>
                                            </input>
                                        </div>
                                        
                                        <div className='phone-input-section'>
                                            <label>Phone</label>
                                            <input
                                                placeholder='Phone'
                                                value={player.phone}
                                                onChange={(e) => {
                                                    editPlayerPhone(playerIndex, e.target.value)
                                                }}>
                                            </input>
                                        </div>

                                        <div className='birthday-input-section'>
                                            <label>Birthday</label>
                                            <DatePicker
                                            className='birthdate-input'
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={90}
                                            maxDate={moment().toDate()}
                                            selected={moment(player.birthday).toDate()}
                                            onChange={(date) => {
                                                editPlayerBirthdate(playerIndex, date);
                                            }}
                                            dateFormat="MMMM d, yyyy"
                                            popperPlacement='top'
                                            />
                                        </div>
                                        {playerCount > 5 ? <div><button className='delete-player-button' onClick={(e) => {removePlayer(playerIndex)}}>Delete player</button></div> : <></>}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        )
                    })}
                </div>
                {/* Add player button */}
                {playerCount < 15 ? <button className='add-player-button' onClick={addPlayer}>Add a player</button> : <></>}
            </div>
            <div className='button-container'>
                {/* current classnames are for visibility purposes, 'clear-button' does not actually clear the application */}
                <button className='save-button' onClick={approveRegistration}>Approve Registration & Create Team</button>
                <button className='clear-button' onClick={saveRegistration}>Save Registration</button>
                <button className='delete-button' onClick={deleteRegistration}>Delete Registration</button>
            </div>
        </div>
    )
}

export default EditRegistration;