import { useEffect, useState, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { db } from './../../../firebase.js'
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { auth } from "./../../../firebase";
// Table
import MaterialReactTable from 'material-react-table';

function ManageGyms() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Create useState instance of an empty array to be populated with the gyms
    const [gyms, setGyms] = useState([]);
    
    // Retrieve divisions from firestore database
    useEffect(() => {

        const getGyms = async () => {
            const gymsCollectionRef = collection(db, "gyms");
            const data = await getDocs(gymsCollectionRef);
            setGyms(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getGyms();
    }, []);

    // Table columns
    const columns = useMemo(
        () => [
            {
                header: 'Gym',
                accessorFn: (row) => row.name,
                Cell: ({cell}) => (
                    <Link to='/edit-gym' state={cell.row.original}>{cell.row.original.name}</Link>
                ),
            },
        ],
        []
    );

    const deleteAll = async () => {
        if(gyms.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to delete all gyms? This cannot be undone")) {
                for(let gym of gyms) {
                    await deleteDoc(doc(db, "gyms", gym.id));
                }
                alert("All gyms deleted successfully");

                // Refresh the page
                navigate(0);
            }
        }
        else {
            alert("No gyms exist");
        }
    }

    return (
        <div className="manage manage-gyms-page">
            <h1 className="page-title">Manage Gyms</h1>
            
                <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
                <Link className="add-link" to='/edit-gym'>Add new gym</Link>

            {/* Table of gyms */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={gyms}
                />
            </div>

        <button className="delete-all-button" onClick={deleteAll}>Delete all gyms</button>
        </div>
    )
}

export default ManageGyms;