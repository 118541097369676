import { useState, useEffect, useMemo } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from './../../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';

function ManageSeasons() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Retrieve all seasons from firestore
    const [seasons, setSeasons] = useState([]);
    
    useEffect(() => {
        const getSeasons = async () => {
            const seasonCollectionRef = collection(db, "seasons");
            const data = await getDocs(seasonCollectionRef);
            setSeasons(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getSeasons();
    })

    // Table columns
    const columns = useMemo(
        () => [
            {
                header: 'Season',
                accessorFn: (row) => row.id,
                Cell: ({cell}) => (
                    <Link to='/edit-season' state={cell.row.original}>{cell.row.original.name}</Link>
                ),
            },
        ],
    );

    const deleteAll = async () => {
        if (seasons.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to delete all seasons? This cannot be undone")) {
                for (let season of seasons) {
                    await deleteDoc(doc(db, "seasons", season.id));
                }
                alert("All seasons deleted successfully");

                // Refresh page
                navigate(0);
            }
        }
        else {
            alert("There are no seasons to delete")
        }
    }

    return (
        <div className="manage manage-seasons-page">
            <h1 className='page-title'>Manage Seasons</h1>

            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
            
            <Link className="add-link" to='/edit-season'>Add new season</Link>

            {/* Seasons table */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={seasons}
                />
            </div>

            <button className="delete-all-button" onClick={deleteAll}>Delete all seasons</button>
        </div>
    )
}

export default ManageSeasons;