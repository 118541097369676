import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, storage} from "./../../../firebase";
import { useNavigate, Link } from "react-router-dom";
import { ref, uploadBytes, listAll, getDownloadURL, getStorage, deleteObject } from "firebase/storage";
import moment from "moment";
// Components
import ImageGallery from "../../components/imageGallery";

function ManageImages() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);
    
    const imageListRef = ref(storage, "images/");
    const[imageArray, setImageArray] = useState([]);
    const[imageUpload, setImageUpload] = useState(null);
    const[deleteAllClicked, setDeleteAllClicked] = useState(false);

    const uploadImage = () => {
        // If no image has been selected for uploading
        if (imageUpload === null) {
            alert("No images have been selected for uploading, please try again. ");
        }
        // Save each image with date, time, and original filename as the new filename
        for (let imageIndex = 0; imageIndex < imageUpload.length; imageIndex++) {
            const newDate = moment().format("YYYY-MM-DD-HH-mm-ss")
            const imageRef = ref(storage, `images/${newDate}-${imageUpload[imageIndex].name}`);
            uploadBytes(imageRef, imageUpload[imageIndex]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((imageURL) => {
                    navigate(0);
                })
            });
        }

        alert("Image(s) have uploaded successfully");
    }

    const deleteAll = () => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('Are you sure you want to delete all images?')) {
            const storage = getStorage();
            const listRef = ref(storage, 'images/');
            let deleteAllSuccess = true;
            let errorMessage = "";
            
            listAll(listRef).then((response) => {
                response.items.forEach((image) => {
                    const imageRef = ref(storage, `images/${image.name}`);
    
                    deleteObject(imageRef).then(() =>{
                    }).catch((error) => {
                        errorMessage = error.message;
                    });
                });
            });
    
            if (!deleteAllSuccess) {
                alert(`Could not delete all images - ${errorMessage}`);
            }
            else {
                alert('All images deleted successfully.');
                setImageArray([]);
            }
        }
    }

    useEffect(() => {
        // Get all images for display
        listAll(imageListRef).then((response) => {
            response.items.forEach((image) => {
                getDownloadURL(image).then((url) => {
                    setImageArray((current) => [...current, {
                        url: url,
                        filename: image.name,
                    }]);
                });
            });
        });
    }, []);
    

    return (
        <div className="manage manage-images-page">
            <h1 className="page-title">Manage Images</h1>
            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link><br />

            <div className="upload-section">
                <h2>Add image</h2>
                <input type="file" className="image-upload" accept="image/*" multiple onChange={(e) => {setImageUpload(e.target.files)}} />
                {imageUpload === null ? <button disabled onClick={uploadImage}>Upload</button> : <button onClick={uploadImage}>Upload</button>}
            </div>
            
            <ImageGallery array={imageArray} admin={true} refLocation="images"/>

            <button className="delete-all-button" onClick={deleteAll}>Delete all images</button>
        </div>
    )
}

export default ManageImages;