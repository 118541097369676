import { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "./../../firebase";
// Components
import AnnouncementCard from "./../components/announcementCard";
// Styling
import './../../styles/announcements.scss';

function Announcements() {
    const [announcements, setAnnouncements] = useState([]);
    // Retrieve announcements from firestore database
    useEffect(() => {
        const getAnnouncements = async () => {
            const announcementCollectionRef = collection(db, "announcements");
            const data = await getDocs(query(announcementCollectionRef, orderBy("datepublished", "desc")));
            setAnnouncements(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getAnnouncements();
    }, []);

    return (
        <div className="announcement-page">
            <h1 className="page-title">Announcements</h1>
            {announcements.length === 0 ? <h2 className='no-announcement-message'>No announcements at the moment, please check again later.</h2> : 
            <div className="announcement-container">
            {announcements.map((announcement) => {
                return (
                    <div className="announcement" key={announcement.id}>
                        {announcement.dateupdated === undefined ? 
                            <AnnouncementCard
                                announcement={announcement}
                                title={announcement.title}
                                body={announcement.body}
                                datepublished={announcement.datepublished}/> : 
                            <AnnouncementCard
                                announcement={announcement}
                                title={announcement.title}
                                body={announcement.body}
                                datepublished={announcement.datepublished}
                                dateupdated={announcement.dateupdated}/>
                            }
                        </div>
                    )
                })}
            </div>
            }
            
        </div>
    )
}

export default Announcements;