import { Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import Divider from '@mui/material/Divider';
import './../../styles/announcementCard.scss';

function AnnouncementCard(props) {
  // List of props:
  // props.title
  // props.body
  // props.datepublished
  // props.dateupdated
  // props.announcement
  // props.admin

  return (
    <div className="announcement-card">
      <h3 className='title'>{props.title}</h3>
      <div className='date-info'>
        <p className='date-published'>Posted {moment(props.datepublished).format("MMMM DD YYYY, h:mm:ss a")}</p>
        {props.dateupdated ? <p className='date-edited'>Edited {moment(props.datepublished).format("MMMM DD YYYY, h:mm:ss a")}</p> : <></>}
      </div>
      {props.admin ? <Link to="/edit-announcement" state={props.announcement}>Edit</Link> : <></>}
      <Divider variant='middle' />
      <div className='announcement-body'>
        <MDEditor.Markdown source={props.body} style={{whiteSpace: 'pre-wrap'}} />
      </div>
    </div>
  );
}

export default AnnouncementCard;