import Carousel from 'react-bootstrap/Carousel';

function PlayerCarousel(props) {
  return (
    <Carousel className='player-carousel' interval={2000}>
        {props.imageList.map((imageURL, index) => {
            return (
                <Carousel.Item
                  key={`potw-${index}`}
                >
                    <img
                      src={imageURL}
                      alt={imageURL}
                    />
                </Carousel.Item>
            )
        })}
    </Carousel>
  );
}

export default PlayerCarousel;