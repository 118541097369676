import './../../styles/about.scss';

function About() {
    return (
        <div className='about-page'>
            <h1 className="page-title">About</h1>
            <div className="about-text">
                <p>The Philippine Sports Association is a community-based association that caters to the Filipino community. </p>
                <p>This web application aims to provide up-to-date information regarding the PSA.</p>
            </div>
        </div>
    )
}

export default About;