import { useEffect, useState, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
// Firebase
import { db, auth } from './../../../firebase.js'
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
// Bootstrap
import Table from 'react-bootstrap/Table';
// Table
import MaterialReactTable from 'material-react-table';
// Moment (for date and time parsing)
import moment from 'moment';

function ManageGames() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Create useState instance of an empty array to be populated with the teams
    const [games, setGames] = useState([]);
    // useState to determine what type of games will be displayed
    const [gameType, setGameType] = useState("");
    
    // Retrieve teams from firestore database
    useEffect(() => {

        const getGames = async () => {
            const gamesCollectionRef = collection(db, "games");
            const data = await getDocs(gamesCollectionRef);
            setGames(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getGames();
    }, []);

    // Columns for table
    const columns = useMemo(
        () => [
            {
                header: 'Game',
                accessorFn: (row) => `${row.teamone} vs. ${row.teamtwo}`,
                Cell: ({cell}) => (
                    <Link to='/edit-game' state={cell.row.original}>{cell.row.original.teamone} vs. {cell.row.original.teamtwo}</Link>
                ),
            },
            {
                header: 'Division',
                accessorFn: (row) => row.division,
            },
            {
                header: 'Status',
                accessorFn: (row) => row.gamecomplete ? 'Completed' : 'Upcoming',
            },
            {
                header: 'Date/Time',
                accessorFn: (row) => row.datetime,
                Cell: ({cell}) => (
                    // let datetime = moment(cell.row.original.datetime);
                    // let dateComponent = datetime.utc().format('M-D-YYYY');
                    // let timeComponent = datetime.utc().format('h:mm A');
                    // <div>
                    //     <p>{dateComponent}</p>
                    //     <p>{timeComponent}</p>
                    // </div>
                    <div>
                        <p>{moment(cell.row.original.datetime).format('M-D-YYYY h:mm A')}</p>
                    </div>
                ),
            },
            {
                header: 'Gym',
                accessorFn: (row) => row.gym,
            },
        ],
        []
    );

    const deleteAll = async () => {
        if(games.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to delete all divisions? This cannot be undone")) {
                for(let game of games) {
                    await deleteDoc(doc(db, "games", game.id));
                }
                alert("All games deleted successfully");

                // Refresh the page
                navigate(0);
            }
        }
        else {
            alert("No games exist");
        }
    }

    return (
        <div className="manage manage-games-page">
            <h1 className="page-title">Manage Games</h1>
            
            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
            <Link className="add-link" to='/edit-game'>Add new game</Link>

            {/* Table */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={games}
                />
            </div>

            <button className="delete-all-button" onClick={deleteAll}>Delete all games</button>
        </div>
    )
}

export default ManageGames;