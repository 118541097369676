import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Moment import
import moment from 'moment';
import DatePicker from 'react-datepicker';
// Firebase imports
import { collection, doc, getDocs, addDoc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './../../../firebase';
// Bootstrap imports
import Alert from 'react-bootstrap/Alert';

function EditTeam() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Team variables
    const [teamId, setTeamId] = useState('');
    const [newTeam, setNewTeam] = useState('');
    const [newDivision, setNewDivision] = useState('');
    const [newGamesPlayed, setNewGamesPlayed] = useState(0);
    const [newWins, setNewWins] = useState(0);
    const [newLosses, setNewLosses] = useState(0);
    const [newPF, setNewPF] = useState(0);
    const [newPA, setNewPA] = useState(0);
    const [newGD, setNewGD] = useState(0);
    const [newPoints, setNewPoints] = useState(0);
    const [players, setPlayers] = useState([])
    const [playerCount, setPlayerCount] = useState(0);
    // Post-CRUD team name variable
    const [updatedTeamName, setUpdatedTeamName] = useState('');
    // State for team editing display
    const [pageDisplay, setPageDisplay] = useState('team');

    const changeDisplay = (event, newValue) => {
        setPageDisplay(newValue);
    }

    // Division variables
    const [divisions, setDivisions] = useState([]);
    useEffect(() => {

        const getDivisions = async () => {
            const divisionsCollectionRef = collection(db, "divisions");
            const data = await getDocs(divisionsCollectionRef);
            setDivisions(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getDivisions();
    }, []);

    const location = useLocation();

    // anonymous function useEffect to prevent infinite looping of setState
    //      - eg. if I try to setNewTeam to team.name outside of useEffect,
    //      an infinite loop occurs
    useEffect(() => {
        if  (location.state !== null) {
            setTeamId(location.state.id);
            setNewTeam(location.state.name);
            setNewDivision(location.state.division);
            setNewGamesPlayed(location.state.gamesplayed);
            setNewWins(location.state.wins);
            setNewLosses(location.state.losses);
            setNewPF(location.state.pf);
            setNewPA(location.state.pa);
            setNewGD(location.state.gd);
            setNewPoints(location.state.points);
            if (location.state.players) {
                setPlayers(location.state.players);
                setPlayerCount(location.state.players.length);
            }
        }
        else {
            setBlankPlayers();
        }
    }, [])

    // Alert visibility states
    const [saveAlertState, setSaveAlertState] = useState(false);
    const [saveWarningAlertState, setSaveWarningAlertState] = useState(false);
    const [deleteAlertState, setDeleteAlertState] = useState(false);
    const [deleteWarningAlertState, setDeleteWarningAlertState] = useState(false);



    // Player info updating functions
    function editPlayerFirstName(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].firstname = value;
        setPlayers(tempPlayers);
    }

    function editPlayerLastName(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].lastname = value;
        setPlayers(tempPlayers);
    }

    function editPlayerEmail(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].email = value;
        setPlayers(tempPlayers);
    }

    function editPlayerPhone(index, value) {
        let tempPlayers = [...players];
        tempPlayers[index].phone = value;
        setPlayers(tempPlayers);
    }

    function editPlayerEligibility(playerIndex, gameIndex, value) {
        let tempPlayers = [...players];

        if (gameIndex === 0) {
            tempPlayers[playerIndex].eligibility0 = value;
        }
        else if (gameIndex === 1) {
            tempPlayers[playerIndex].eligibility1 = value;
        }
        else {
            tempPlayers[playerIndex].eligibility2 = value;
        }

        setPlayers(tempPlayers);
    }

    function editPlayerBirthdate(index, value) {
        console.log(moment(value).format());
        let tempPlayers = [...players];
        tempPlayers[index].birthday = moment(value).format();
        setPlayers(tempPlayers);
    }

    function setBlankPlayers() {
        setPlayers([{
            birthday: moment(),
            firstname: '',
            lastname: '',
            leader: true,
            minor: true,
            phone: '',
            eligibility0: false,
            eligibility1: false,
            eligibility2: false,
        },
        {
            birthday: moment(),
            firstname: '',
            lastname: '',
            leader: false,
            minor: true,
            phone: '',
            eligibility0: false,
            eligibility1: false,
            eligibility2: false,
        },
        {
            birthday: moment(),
            firstname: '',
            lastname: '',
            leader: false,
            minor: true,
            phone: '',
            eligibility0: false,
            eligibility1: false,
            eligibility2: false,
        },
        {
            birthday: moment(),
            firstname: '',
            lastname: '',
            leader: false,
            minor: true,
            phone: '',
            eligibility0: false,
            eligibility1: false,
            eligibility2: false,
        },
        {
            birthday: moment(),
            firstname: '',
            lastname: '',
            leader: false,
            minor: true,
            phone: '',
            eligibility0: false,
            eligibility1: false,
            eligibility2: false,
        },
    ]);
        setPlayerCount(5);
    }

    function addPlayer() {
        if (playerCount < 15) {
            setPlayers([...players, {
                birthday: moment(),
                firstname: '',
                lastname: '',
                leader: false,
                minor: true,
                phone: '',
                eligibility0: false,
                eligibility1: false,
                eligibility2: false,
            }]);
            setPlayerCount(playerCount + 1);
        }
    }

    function removePlayer(index) {
        setPlayers([
            ...players.slice(0, index),
            ...players.slice(index + 1),
        ]);
        setPlayerCount(playerCount - 1);
    }

    // Clear all fields
    const clearFields = () => {
            setTeamId('');
            setNewTeam('');
            setNewDivision('');
            setNewGamesPlayed(0);
            setNewWins(0);
            setNewLosses(0);
            setNewPF(0);
            setNewPA(0);
            setNewGD(0);
            setNewPoints(0);
            setSaveAlertState(false);
            setSaveWarningAlertState(false);
            setDeleteAlertState(false);
            setDeleteWarningAlertState(false);
            setBlankPlayers();
            
            window.scrollTo(0,0);
    }

    // Click event for when user wants to save their team
    // const [newTeam, setNewTeam] = useState('');
    const saveTeam = async () => {
        // Check if user has input a team name, selected a division before saving, and the team has 5 or more players
        // if (newTeam !== '' && newDivision !== '' && playerCount > 5) {
        if (newTeam !== '' && newDivision !== '') {
            // If team was previously made, overwrite stats
            if (teamId !== '') {
                if (playerCount > 5) {
                    await setDoc(doc(db, "teams", teamId), {
                        name: newTeam,
                        division: newDivision,
                        gamesplayed: (isNaN(newGamesPlayed) ? 0 : parseInt(newGamesPlayed)),
                        wins: (isNaN(newWins) ? 0 : parseInt(newWins)),
                        losses: (isNaN(newLosses) ? 0 : parseInt(newLosses)),
                        pf: (isNaN(newPF) ? 0 : parseInt(newPF)),
                        pa: (isNaN(newPA) ? 0 : parseInt(newPA)),
                        gd: (isNaN(newGD) ? 0 : parseInt(newGD)),
                        points: (isNaN(newPoints) ? 0 : parseInt(newPoints)),
                        players: players,
                    });
                }
                else {
                    await setDoc(doc(db, "teams", teamId), {
                        name: newTeam,
                        division: newDivision,
                        gamesplayed: (isNaN(newGamesPlayed) ? 0 : parseInt(newGamesPlayed)),
                        wins: (isNaN(newWins) ? 0 : parseInt(newWins)),
                        losses: (isNaN(newLosses) ? 0 : parseInt(newLosses)),
                        pf: (isNaN(newPF) ? 0 : parseInt(newPF)),
                        pa: (isNaN(newPA) ? 0 : parseInt(newPA)),
                        gd: (isNaN(newGD) ? 0 : parseInt(newGD)),
                        points: (isNaN(newPoints) ? 0 : parseInt(newPoints)),
                    });
                }
                
            }
            // If team is a new addition, add a new team with unique id
            else {
                if (playerCount > 5) {
                    const docRef = await addDoc(collection(db, "teams"), {
                        name: newTeam,
                        division: newDivision,
                        gamesplayed: (isNaN(newGamesPlayed) ? 0 : parseInt(newGamesPlayed)),
                        wins: (isNaN(newWins) ? 0 : parseInt(newWins)),
                        losses: (isNaN(newLosses) ? 0 : parseInt(newLosses)),
                        pf: (isNaN(newPF) ? 0 : parseInt(newPF)),
                        pa: (isNaN(newPA) ? 0 : parseInt(newPA)),
                        gd: (isNaN(newGD) ? 0 : parseInt(newGD)),
                        points: (isNaN(newPoints) ? 0 : parseInt(newPoints)),
                        players: players,
                    });
                    setTeamId(docRef.id);
                }
                else {
                    const docRef = await addDoc(collection(db, "teams"), {
                        name: newTeam,
                        division: newDivision,
                        gamesplayed: (isNaN(newGamesPlayed) ? 0 : parseInt(newGamesPlayed)),
                        wins: (isNaN(newWins) ? 0 : parseInt(newWins)),
                        losses: (isNaN(newLosses) ? 0 : parseInt(newLosses)),
                        pf: (isNaN(newPF) ? 0 : parseInt(newPF)),
                        pa: (isNaN(newPA) ? 0 : parseInt(newPA)),
                        gd: (isNaN(newGD) ? 0 : parseInt(newGD)),
                        points: (isNaN(newPoints) ? 0 : parseInt(newPoints)),
                    });
                    setTeamId(docRef.id);
                }
            }
            
            setUpdatedTeamName(newTeam);
            clearFields();
            setDeleteAlertState(false);
            setDeleteWarningAlertState(false);
            setSaveWarningAlertState(false);
            setSaveAlertState(true);
        }
        else {
            setDeleteAlertState(false);
            setDeleteWarningAlertState(false);
            setSaveAlertState(false);
            setSaveWarningAlertState(true);
        }

        window.scrollTo(0,0);
    }

    // Click event for when user wants to delete the team
    const deleteTeam = async () => {
        if (teamId !== '') {
            // Find specified team in Firestore
            const docRef = doc(db, "teams", teamId);
            const docSnap = await getDoc(docRef);

            // If team exists, delete it and tell user
            if (docSnap.exists()) {
                // Save team name for notification purposes before deleting
                setUpdatedTeamName(newTeam);
                await deleteDoc(doc(db, "teams", teamId));
                clearFields();
                setSaveAlertState(false);
                setSaveWarningAlertState(false);
                setDeleteWarningAlertState(false);
                setDeleteAlertState(true);
            }
            // If team DOES NOT exist, do not delete, warn the user it does not exist
            else {
                setSaveAlertState(false);
                setSaveWarningAlertState(false);
                setDeleteAlertState(false);
                setDeleteWarningAlertState(true);
            }
        }
        else {
            alert('Cannot delete a team that does not exist');
        }

        window.scrollTo(0,0);
    }

    return (
        <div className='edit edit-team-page'>
            <Alert variant='success' show={saveAlertState}>Team "{updatedTeamName}" saved successfully.</Alert>
            <Alert variant='warning' show={saveWarningAlertState}>Save unsuccessful, one or more required fields are missing.</Alert>
            <Alert variant='danger' show={deleteAlertState}>Team "{updatedTeamName}" deleted successfully.</Alert>
            <Alert variant='warning' show={deleteWarningAlertState}>Team "{updatedTeamName}" does not exist.</Alert>
            
            <h1 className='page-title'>Edit Team</h1>

            <Link className="management-link" to='/manage-teams'>Back to Team Management Page</Link>

            {/* Page display buttons */}
            <Tabs
                    value={pageDisplay}
                    onChange={changeDisplay}
                    aria-label="team editing display"
                    sx={{width: 'fit-content', margin: '1rem auto'}}
                >
                    <Tab
                        value="team"
                        label="Edit Team"
                    />
                    <Tab
                        value="players"
                        label="Players"
                    />
                </Tabs>

            {/* CRUD functionality*/}
            {pageDisplay === 'team' ?
                // Team info
                <>
                    <div className='user-input name-section'>
                        <label>Team Name</label>
                        <input
                            placeholder="Team name"
                            value={newTeam}
                            onChange={(e) => {setNewTeam(e.target.value)}}>
                        </input>
                    </div>

                    <div className='user-input division-section'>
                        <label>Division</label>
                        <select
                            value={newDivision}
                            onChange={(e) => {setNewDivision(e.target.value)}}>
                            <option value="">Select a division</option>
                            {divisions.map((division) => {
                                return (
                                    <option
                                        key={division.name}
                                        value={division.name}
                                    >{division.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    
                    <div className='user-input games-played-section'>
                        <label>Games Played</label>
                        <input
                            type="number"
                            placeholder="Games played"
                            value={newGamesPlayed}
                            onChange={(e) => {setNewGamesPlayed(e.target.value)}}>
                        </input>
                    </div>
                    
                    <div className='user-input wins-section'>
                        <label>Wins</label>
                        <input
                            type="number"
                            placeholder="Wins"
                            value={newWins}
                            onChange={(e) => {setNewWins(e.target.value)}}>
                        </input>
                    </div>
                    
                    <div className='user-input losses-section'>
                        <label>Losses</label>
                        <input
                            type="number"
                            placeholder="Losses"
                            value={newLosses}
                            onChange={(e) => {setNewLosses(e.target.value)}}>
                        </input>
                    </div>
                    
                    <div className='user-input points-for-section'>
                        <label>Points For</label>
                        <input
                            type="number"
                            placeholder="PF"
                            value={newPF}
                            onChange={(e) => {setNewPF(e.target.value)}}>
                        </input>
                    </div>
                    
                    <div className='user-input points-against-section'>
                        <label>Points Against</label>
                        <input
                            type="number"
                            placeholder="PA"
                            value={newPA}
                            onChange={(e) => {setNewPA(e.target.value)}}>
                        </input>
                    </div>

                    <div className='user-input game-difference-section'>
                        <label>Game Difference</label>
                        <input
                            type="number"
                            placeholder="GD"
                            value={newGD}
                            onChange={(e) => {setNewGD(e.target.value)}}>
                        </input>
                    </div>
                    
                    <div className='user-input points-section'>
                        <label>Points</label>
                        <input
                            type="number"
                            placeholder="Points"
                            value={newPoints}
                            onChange={(e) => {setNewPoints(e.target.value)}}>
                        </input>
                    </div>
                </>
                :
                // Player info
                <>
                    <div className='user-input players-section'>
                        {/* Leader */}
                        {players.map((player, playerIndex) => {
                            return (
                                <div className='leader-inputs' key={`player${playerIndex}`}>
                                    {player.leader ?
                                        <div className='player-inputs'>
                                            <label className='leader-title'>Leader</label>
                                            <div className='player-name'>
                                                <input
                                                    placeholder='First Name'
                                                    value={player.firstname}
                                                    onChange={(e) => {
                                                        editPlayerFirstName(playerIndex, e.target.value);
                                                    }}>
                                                </input>
                                                <input
                                                    placeholder='Last Name'
                                                    value={player.lastname}
                                                    onChange={(e) => {
                                                        editPlayerLastName(playerIndex, e.target.value);
                                                    }}
                                                >
                                                </input>
                                            </div>
                                            <div className='eligibility-input-section'>
                                                <label>Eligibility game(s) played&nbsp;</label>
                                                <div className='eligibility-groups'>
                                                    <div className='eligibility-game'>
                                                        <label>Game 1:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility0 ? player.eligibility0 : false}
                                                            value={player.eligibility0 ? player.eligibility0 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 0, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='eligibility-game'>
                                                        <label>Game 2:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility1 ? player.eligibility1 : false}
                                                            value={player.eligibility1 ? player.eligibility1 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 1, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='eligibility-game'>
                                                        <label>Game 3:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility2 ? player.eligibility2 : false}
                                                            value={player.eligibility2 ? player.eligibility2 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 2, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {player.email ?
                                                <div className='email-input-section'>
                                                    <label>Email</label>
                                                    <input
                                                        placeholder='Email'
                                                        value={player.email}
                                                        onChange={(e) => {
                                                            editPlayerEmail(playerIndex, e.target.value)
                                                        }}
                                                    >
                                                    </input>
                                                </div> : <></>}
                                            
                                            <div className='phone-input-section'>
                                                <label>Phone</label>
                                                <input
                                                    placeholder='Phone'
                                                    value={player.phone}
                                                    onChange={(e) => {
                                                        editPlayerPhone(playerIndex, e.target.value)
                                                    }}>
                                                </input>
                                            </div>

                                            <div className='birthday-input-section'>
                                                <label>Birthday</label>
                                                <DatePicker
                                                className='birthdate-input'
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={90}
                                                maxDate={moment().toDate()}
                                                selected={moment(player.birthday).toDate()}
                                                onChange={(date) => {
                                                    editPlayerBirthdate(playerIndex, date);
                                                }}
                                                dateFormat="MMMM d, yyyy"
                                                popperPlacement='top'
                                                />
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            )
                        })}
                        <label className='players-title'>Players</label>
                        {/* Other players */}
                        {players.map((player, playerIndex) => {
                            return (
                                <div className='other-player-inputs' key={`player${playerIndex}`}>
                                    {!player.leader ?
                                        <div className='player-inputs'>
                                            <label>Name</label>
                                            <div className='player-name'>
                                                <input
                                                    placeholder='First Name'
                                                    value={player.firstname}
                                                    onChange={(e) => {
                                                        editPlayerFirstName(playerIndex, e.target.value);
                                                    }}>
                                                </input>
                                                <input
                                                    placeholder='Last Name'
                                                    value={player.lastname}
                                                    onChange={(e) => {
                                                        editPlayerLastName(playerIndex, e.target.value);
                                                    }}>
                                                </input>
                                            </div>
                                            

                                            <div className='eligibility-input-section'>
                                                <label>Eligibility game(s) played&nbsp;</label>
                                                <div className='eligibility-groups'>
                                                    <div className='eligibility-game'>
                                                        <label>Game 1:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility0 ? player.eligibility0 : false}
                                                            value={player.eligibility0 ? player.eligibility0 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 0, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='eligibility-game'>
                                                        <label>Game 2:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility1 ? player.eligibility1 : false}
                                                            value={player.eligibility1 ? player.eligibility1 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 1, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='eligibility-game'>
                                                        <label>Game 3:</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={player.eligibility2 ? player.eligibility2 : false}
                                                            value={player.eligibility2 ? player.eligibility2 : false}
                                                            onChange={(e) => {
                                                                editPlayerEligibility(playerIndex, 2, e.target.checked)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {player.email ?
                                                <div className='email-input-section'>
                                                    <label>Email</label>
                                                    <input
                                                        placeholder='Email'
                                                        value={player.email}
                                                        onChange={(e) => {
                                                            editPlayerEmail(playerIndex, e.target.value)
                                                        }}
                                                    >
                                                    </input>
                                                </div> : <></>}
                                            
                                            <div className='phone-input-section'>
                                                <label>Phone</label>
                                                <input
                                                    placeholder='Phone'
                                                    value={player.phone}
                                                    onChange={(e) => {
                                                        editPlayerPhone(playerIndex, e.target.value)
                                                    }}>
                                                </input>
                                            </div>

                                            <div className='birthday-input-section'>
                                                <label>Birthday</label>
                                                <DatePicker
                                                className='birthdate-input'
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={90}
                                                maxDate={moment().toDate()}
                                                selected={moment(player.birthday).toDate()}
                                                onChange={(date) => {
                                                    editPlayerBirthdate(playerIndex, date);
                                                }}
                                                dateFormat="MMMM d, yyyy"
                                                popperPlacement='top'
                                                />
                                            </div>

                                            {playerCount > 5 ? <button onClick={(e) => {removePlayer(playerIndex)}} className='delete-player-button'>Delete player</button> : <></>}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            )
                        })}

                        {/* Add player button */}
                        {playerCount < 15 ?
                            <button onClick={addPlayer} className='add-player-button'>Add a player</button>
                            :
                            <button onClick={addPlayer} className='add-player-button' disabled>Add a player</button>}
                    </div>
                </>
            }
            

            
            
            
            
            <div className='button-container'>
                <button className='save-button' onClick={saveTeam}>Save</button>
                <button className='clear-button' onClick={clearFields}>Clear</button>
                <button className='delete-button' onClick={deleteTeam}>Delete</button>
            </div>
        </div>
    )
}

export default EditTeam;