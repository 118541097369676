import { Route, Routes, useLocation } from 'react-router-dom';
// Standard Pages
import Home from '../pages/home.js';
import About from '../pages/about.js';
import Standings from '../pages/standings.js';
import Games from '../pages/games.js';
import Announcements from '../pages/announcements.js';
import Gallery from '../pages/gallery.js';
import Contact from '../pages/contact.js';
import Login from '../pages/login.js';
import Register from '../pages/register.js';
import ErrorPage from '../pages/errorPage.js';
import WaiverUpload from '../pages/waiverUpload.js';
import Rules from '../pages/rules.js';
import Sponsors from '../pages/sponsors.js';
// Admin pages
import Dashboard from '../pages/admin/dashboard.js';
import ManageTeams from '../pages/admin/manageTeams.js';
import ManageDivisions from '../pages/admin/manageDivisions.js';
import ManageGames from '../pages/admin/manageGames.js';
import ManageGyms from '../pages/admin/manageGyms.js';
import ManageAnnouncements from '../pages/admin/manageAnnouncements.js';
import ManageImages from '../pages/admin/manageImages.js';
import ManagePOTW from '../pages/admin/managePOTW.js';
import ManageSeasons from '../pages/admin/manageSeasons.js';
import ManageRegistrations from '../pages/admin/manageRegistrations.js';
import EditTeam from '../pages/admin/editTeam.js';
import EditDivision from '../pages/admin/editDivision.js';
import EditGame from '../pages/admin/editGame.js';
import EditGym from '../pages/admin/editGym.js';
import EditAnnouncement from '../pages/admin/editAnnouncement.js';
import EditSeason from '../pages/admin/editSeason.js';
import EditRegistration from '../pages/admin/editRegistration.js';
import ViewWaivers from '../pages/admin/viewWaivers.js';

function PSARoutes() {
    const location = useLocation();

    return(
        <Routes location={location} key={location.pathname}>
            {/* Standard pages */}
            <Route path='/' element={<Home />} />
            <Route path='/standings' element={<Standings />} />
            <Route path='/games' element={<Games />} />
            <Route path='/announcements' element={<Announcements />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/waiver-upload' element={<WaiverUpload />} />
            <Route path='/rules' element={<Rules />} />
            <Route path='/sponsors' element={<Sponsors />} />
            {/* Admin pages */}
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/manage-teams' element={<ManageTeams />}/>
            <Route path='/manage-divisions' element={<ManageDivisions />}/>
            <Route path='/manage-games' element={<ManageGames />}/>
            <Route path='/manage-gyms' element={<ManageGyms />}/>
            <Route path='/manage-announcements' element={<ManageAnnouncements />}/>
            <Route path='/manage-images' element={<ManageImages />}/>
            <Route path='/manage-potw' element={<ManagePOTW />}/>
            <Route path='/manage-seasons' element={<ManageSeasons />}/>
            <Route path='/manage-registrations' element={<ManageRegistrations />}/>
            <Route path='/edit-team' element={<EditTeam />}/>
            <Route path='/edit-division' element={<EditDivision />}/>
            <Route path='/edit-game' element={<EditGame />}/>
            <Route path='/edit-gym' element={<EditGym />}/>
            <Route path='/edit-announcement' element={<EditAnnouncement />}/>
            <Route path='/edit-season' element={<EditSeason />}/>
            <Route path='/edit-registration' element={<EditRegistration />}/>
            <Route path='/view-waivers' element={<ViewWaivers />}/>
            {/* No match */}
            <Route path='*' element={<ErrorPage />}/>
        </Routes>
    )
}

export default PSARoutes;