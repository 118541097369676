// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator} from '@firebase/firestore';
// authentication
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
// storage
import { getStorage, connectStorageEmulator } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// FIREBASE EMULATORS:
// Console command:
//    firebase emulators:start
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectStorageEmulator(storage, 'localhost', 9199);



// Authentication
// Login with email/password
const logInWithEmailAndPassword = async(email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert("The credentials you have entered are invalid. ");
  }
}

const logout = () => {
  signOut(auth);
}

export {app, db, auth, storage, logInWithEmailAndPassword, logout};