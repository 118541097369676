import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { doc, collection, addDoc, setDoc, getDoc, deleteDoc }from 'firebase/firestore';
import { db, auth } from './../../../firebase';
import { Alert } from 'react-bootstrap';

function EditSeason() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Season variables
    const [newSeason, setNewSeason] = useState('');
    const [seasonId, setNewSeasonId] = useState('');
    const location = useLocation();

    // 
    const [alertVisibility, setAlertVisibility] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    // Post-CRUD season name variable
    const [updatedSeason, setUpdatedSeason] = useState('');

    // anonymous function useEffect to prevent infinite looping of setState
    //      - if I try to setNewDivision to division.name outside of useEffect,
    //      an infinite loop occurs
    useEffect(() => {
        if (location.state !== null) {
            setNewSeason(location.state.name);
            setNewSeasonId(location.state.id);
        }
    }, [])

    const clearFields = () => {
        setNewSeason('');
        setNewSeasonId('');
        setAlertVisibility(false);

        window.scrollTo(0,0);
    }

    const saveSeason = async () => {
        if (newSeason !== '') {
            // If saving a new season, add to collection with unique id
            // If season was already made, overwrite it
            if (seasonId === '') {
                const docRef = await addDoc(collection(db, "seasons"), {
                    name: newSeason,
                })
                setNewSeasonId(docRef.id);
            }
            else {
                await setDoc(doc(db, "seasons", seasonId), {
                    name: newSeason,
                })
            }

            // Show alert to tell user the save was successful
            setAlertType('success');
            setAlertMessage(`Successfully saved ${newSeason}`);
        }
        else {
            setAlertType('warning');
            setAlertMessage('No season was entered, please try again');
        }
        clearFields();
        setAlertVisibility(true);

        window.scrollTo(0,0);
    }

    const deleteSeason = async () => {
        if (seasonId !== '') {
            // Find season in Firestore
            const docRef = doc(db, "seasons", seasonId);
            const docSnap = await getDoc(docRef);

            // If the season exists, delete it
            if (docSnap.exists()) {
                await deleteDoc(doc(db, "seasons", seasonId));
                setUpdatedSeason(newSeason);

                clearFields();
                // Show alert to tell user the deletion was successful
                setAlertType('danger');
                setAlertMessage(`Successfully deleted ${updatedSeason}`);
            }
            // If season does not exist, warn the user
            else {
                setAlertType('warning');
                setAlertMessage('Season does not exist, cannot delete');
            }
            setAlertVisibility(true);
        }
        else {
            alert('Cannot delete a season that does not exist');
        }

        window.scrollTo(0,0);
    }

    return (
        <div className="edit edit-season-page">
            
            <h1 className='page-title'>Edit Season</h1>
            <Alert key={alertType} variant={alertType} show={alertVisibility}>{alertMessage}</Alert>

            <Link className='management-link' to='/manage-seasons'>Back to Season Management Page</Link>

            <div className='user-input'> 
            <label>Season</label>
                <input
                    placeholder='Season'
                    value={newSeason}
                    onChange={(e) => {setNewSeason(e.target.value)}}>
                </input>
            </div>

            <div className='button-container'>
                <button className='save-button' onClick={saveSeason}>Save</button>
                <button className='clear-button' onClick={clearFields}>Clear</button>
                <button className='delete-button' onClick={deleteSeason}>Delete</button>
            </div>

        </div>
    )
}

export default EditSeason;