import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useLocation } from 'react-router-dom';
// Firebase imports
import { doc, collection, addDoc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './../../../firebase';
// Bootstrap imports
import Alert from 'react-bootstrap/Alert';
// Styling
import '../../../styles/edit.scss'

function EditDivision() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Division variables
    const [newDivision, setNewDivision] = useState('');
    const [divisionId, setDivisionId] = useState('');
    const location = useLocation();
    let division;

    // Post-CRUD division name variable
    const [updatedDivision, setUpdatedDivision] = useState('');

    // anonymous function useEffect to prevent infinite looping of setState
    //      - if I try to setNewDivision to division.name outside of useEffect,
    //      an infinite loop occurs
    useEffect(() => {
        if  (location.state !== null) {
            setNewDivision(location.state.name);
            setDivisionId(location.state.id);
        }
    }, [])

    // Alert visibility states
    const [saveAlertState, setSaveAlertState] = useState(false);
    const [deleteAlertState, setDeleteAlertState] = useState(false);
    const [warningAlertState, setWarningAlertState] = useState(false);

        // Clear all fields
    const clearFields = () => {
        setNewDivision('');
        setDivisionId('');

        window.scrollTo(0,0);
    }

    // Click event for when user wants to save their division
    // const [newDivision, setNewDivision] = useState('');
    const saveDivision = async () => {
        // If saving a new division, add to collection with an id
        // If saving a previously-made division, overwrite the name
        if(newDivision !== '') {
            if (divisionId !== '') {
                await setDoc(doc(db, "divisions", divisionId), {
                    name: newDivision,
                })
            }
            else {
                const docRef = await addDoc(collection(db, "divisions"), {
                    name: newDivision,
                })
                setDivisionId(docRef.id);
            }

            setUpdatedDivision(newDivision)
            setSaveAlertState(true);
            clearFields();
        }
        else {
            setWarningAlertState(true);
        }

        window.scrollTo(0,0);
    }

    // Click event for when user wants to delete the division
    const deleteDivision = async () => {
        // Check to see if the division exists
        if (divisionId !== '') {
            // Find specified division in Firestore
            const docRef = doc(db, "divisions", divisionId);
            const docSnap = await getDoc(docRef);

            // If division exists, delete it and tell user
            if (docSnap.exists()) {
                await deleteDoc(doc(db, "divisions", divisionId));
                setUpdatedDivision(newDivision)
                setDivisionId('');
                clearFields();
                setDeleteAlertState(true);
            }
            // If division DOES NOT exist, do not delete, warn the user it does not exist
            else {
                setWarningAlertState(true);
            }
        }
        else {
            alert('Cannot delete a division that does not exist');
        }

        window.scrollTo(0,0);
    }

    return (
        <div className='edit edit-division-page'>
            <Alert variant='success' show={saveAlertState}>Division "{updatedDivision}" saved successfully.</Alert>
            <Alert variant='danger' show={deleteAlertState}>Division "{updatedDivision}" deleted successfully.</Alert>
            <Alert variant='warning' show={warningAlertState}>Division "{updatedDivision}" does not exist.</Alert>
            
            <h1 className='page-title'>Edit Division</h1>

            <Link className="management-link" to='/manage-divisions'>Back to Division Management Page</Link>

            {/* CRUD functionality*/}
            <div className='user-input division-section'>
                <label>Name</label>
                <input
                    placeholder="Division name"
                    value={newDivision}
                    onChange={(e) => {setNewDivision(e.target.value)}}>
                </input>
            </div>
            
            <div className='button-container'>
                <button className='save-button' onClick={saveDivision}>Save</button>
                <button className='clear-button' onClick={clearFields}>Clear</button>
                <button className='delete-button' onClick={deleteDivision}>Delete</button>
            </div>
        </div>
    )
}

export default EditDivision;