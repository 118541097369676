import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
// Styling
import '../../styles/contact.scss';

function Contact () {
    const form = useRef();
    const [showMessage, setShowMessage] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        // console.log(form.current.message.value === "");

        // Check if all fields are not empty
        if (form.current.from_name.value !== ""
            && form.current.reply_to.value !== ""
            && form.current.message.value !== "") {
                emailjs.sendForm('service_dazdvjw', 'template_62pg3n7', form.current, 'CqX_PMKaZAHH3TIrx')
                // If message send was successful
                .then(
                    function(response) {
                        setMessageSuccess(true);
                        setMessage("Your message has sent successfully!");
                    },
                    // If message send failed
                    function(error) {
                        setMessageSuccess(false);
                        setMessage("The message failed to send, please try again. ")
                });

                form.current.from_name.value = "";
                form.current.reply_to.value = "";
                form.current.message.value = "";
        } else {
            setMessageSuccess(false);
            setMessage("One of the required fields is missing, please try again. ");
        }

        setShowMessage(true);
    }

    return (
        <div className="contact-page">
            <h1 className='page-title'>Contact</h1>
            <div className='contact-body'>
                <p className='contact-description'>Shoot us a message here, and we will respond through the email you provide!</p>

                {showMessage ? <Alert severity={messageSuccess ? "success" : "warning"}>{message}</Alert> : <></>}

                <form ref={form} onSubmit={sendEmail}>
                    <div className="contact-name">
                        <label>Name</label>
                        <input type="text" name="from_name" placeholder="Name" />
                    </div>

                    <div className="contact-email">
                        <label>Email</label>
                        <input type="email" name="reply_to" placeholder="Email"/>
                    </div>

                    <div className="contact-message">
                        <label>Message</label>
                        <textarea name="message" placeholder="Enter your message here"/>
                    </div>

                    <input className='contact-button' type="submit" value="Send" />
            </form>
            </div>
        </div>
    )
}

export default Contact;