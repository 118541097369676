import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, storage } from "./../../../firebase";
import { ref, uploadBytes, listAll, getDownloadURL, getStorage, deleteObject } from "firebase/storage";
// Components
import ImageGallery from "../../components/imageGallery";

function ManagePOTW () {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    const playerImageListRef = ref(storage, "potw/");
    const[playerImageArray, setPlayerImageArray] = useState([]);
    const[playerImageUpload, setPlayerImageUpload] = useState(null);

    const uploadPlayerImage = () => {
        // If no image has been selected for uploading
        if (playerImageUpload === null) {
            alert("No images have been selected for uploading, please try again. ");
        }
        // Save each image with date, time, and original filename as the new filename
        for (let imageIndex = 0; imageIndex < playerImageUpload.length; imageIndex++) {
            const imageRef = ref(storage, `potw/${playerImageUpload[imageIndex].name}`);
            uploadBytes(imageRef, playerImageUpload[imageIndex]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((imageURL) => {
                    navigate(0);
                })
            });
        }

        alert("Image(s) have uploaded successfully");
    }

    const deleteAll = () => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('Are you sure you want to delete all images?')) {
            const storage = getStorage();
            const listRef = ref(storage, 'potw/');
            let deleteAllSuccess = true;
            let errorMessage = "";

            listAll(listRef).then((response) => {
                response.items.forEach((image) => {
                    const imageRef = ref(storage, `potw/${image.name}`);
                    console.log(image.name);

                    deleteObject(imageRef).then(() =>{
                    }).catch((error) => {
                        errorMessage = error.message;
                    });
                });
            })

            if (!deleteAllSuccess) {
                alert(`Could not delete all images - ${errorMessage}`);
            }
            else {
                alert('All images deleted successfully.');
                setPlayerImageArray([]);
            }
        }
    }

     // Get all images for display
     useEffect(() => {
        listAll(playerImageListRef).then((response) => {
            response.items.forEach((image) => {
                getDownloadURL(image).then((url) => {
                    setPlayerImageArray((current) => [...current, {
                        url: url,
                        filename: image.name,
                    }])
                });
            });
        });
    }, []);

    return (
        <div className="manage manage-potw-page">
            <h1 className="page-title">Manage Player of the Week</h1>
            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link><br />

            <div className="upload-section">
                <h2>Add image</h2>
                <input type="file" className="image-upload" accept="image/*" multiple onChange={(e) => {setPlayerImageUpload(e.target.files)}} />
                {playerImageUpload === null ? <button disabled onClick={uploadPlayerImage}>Upload</button> : <button onClick={uploadPlayerImage}>Upload</button>}
            </div>

            <ImageGallery array={playerImageArray} admin={true} refLocation="potw"/>
            
            <button className="delete-all-button" onClick={deleteAll}>Delete all images</button>
        </div>
    )
}

export default ManagePOTW;