import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage } from './../../firebase';
import { ref, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import { ReactComponent as UploadIcon} from './../../assets/icons/folder-upload.svg';
import '../../styles/waiverUpload.scss';

function WaiverUpload () {
    let navigate = useNavigate();

    const [fileUpload, setFileUpload] = useState(null);
    let fileCount = 0;

    const uploadWaiver = () => {
        if (fileUpload === null) {
            alert("No file has been selected for uploading. Please try again");
        }
        else {
            for (let fileIndex = 0; fileIndex < fileUpload.length; fileIndex++) {
                let currentTime = moment().format('YYYY-MM-DD-HH-mm-ss');
                const fileRef = ref(storage, `waivers/${currentTime}-${fileUpload[fileIndex].name}`);
                uploadBytes(fileRef, fileUpload[fileIndex]).then(fileCount++);
            }

            alert(`${fileCount} file(s) uploaded successfully.`);
            fileCount = 0;
            navigate('/register');
        }
    }

    return (
        <div className='waiver-upload-page'>
            <h1 className='page-title'>Waiver Upload</h1>
            <div className='flavour-text'>
                Please make sure that your waivers have the team name on them before submitting!
            </div>

            <div className='file-upload-section'>
                <input type="file" className="image-upload" accept="image/*,.pdf" multiple onChange={(e) => {setFileUpload(e.target.files)}} />
                {fileUpload === null ?
                    <button className='upload-button' onClick={uploadWaiver} disabled><UploadIcon className='upload-icon' /><p>Upload Waiver</p></button>
                    :
                    <button className='upload-button' onClick={uploadWaiver}><UploadIcon className='upload-icon'/><p>Upload Waiver</p></button>
                }
            </div>
        </div>
    )
}

export default WaiverUpload;