import { Link } from 'react-router-dom';
// Error svg
import { ReactComponent as ErrorSymbol } from './../../assets/icons/notification.svg';

function ErrorPage() {
    return (
        <div className="error-page">
            <div className='error-section'>
                <ErrorSymbol className='error-symbol' />
                <div className='error-text'>
                    <h3>Page not found</h3>
                    <Link to='/'>Take me back!</Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;