import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import StandingsTable from './standingsTable';
// Styling
import './../../styles/standingsModal.scss';


function StandingsModal(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000',
    // border: '2px solid #000',
    boxShadow: 24
  };

  return (
    <>
      <button onClick={handleOpen} className='modal-button'>
        {props.division.name}
      </button>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <StandingsTable teams={props.teams} division={props.division} />
        </Box>
      </Modal>
    </>
  );
}

export default StandingsModal;