import { useState, useEffect, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';

function ManageRegistrations() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    const [registrations, setRegistrations] = useState([]);

    // Get registrations from firestore
    useEffect(() => {
        const getRegistrations = async () => {
            const registrationCollectionRef = collection(db, "registrations");
            const data = await getDocs(registrationCollectionRef);
            setRegistrations(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getRegistrations();
    }, [])

    // Table columns
    const columns = useMemo(
        () => [
            {
                header: 'Team Name',
                accessorFn: (row) => row.teamname,
                Cell: ({cell}) => (
                    <Link to='/edit-registration' state={cell.row.original}>{cell.row.original.teamname}</Link>
                ),
            },
            {
                header: 'Season',
                accesorFn: (row) => row.season,
                Cell: ({cell}) => (
                    <p>{cell.row.original.season}</p>
                ),
            },
        ],
        []
    );

    return (
        <div className="manage manage-registrations-page">
            <h1 className='page-title'>Manage Registrations</h1>

            <div className="manage-buttons">
                <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
                {/* <button className="admin-link delete-all" onClick={deleteAll}>Delete all registrations</button> */}
            </div>

            {/* Table */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={registrations}
                />
            </div>
        </div>
    )
}

export default ManageRegistrations;