import Divider from '@mui/material/Divider';
import './../../styles/rules.scss';

function jumpTo(element) {
    document.getElementById(element).scrollIntoView({behavior: 'smooth'});
}

function Rules () {
    return (
        <div className="manage rules-page">
            <h1 className='page-title'>Rules</h1>

            <div className='rules-splash'>
                <div className='rule-directory'>
                    <p className='directory-prompt'>Jump to:</p>
                    <div className='directory-buttons'>
                        <button onClick={(e) => {jumpTo('game-rules')}}>Game Rules</button>
                        <button onClick={(e) => {jumpTo('house-rules')}}>House Rules</button>
                        <button onClick={(e) => {jumpTo('gym-rules')}}>Gym Rules</button>
                        <button onClick={(e) => {jumpTo('jersey-rules')}}>Jersey Rules</button>
                        <button onClick={(e) => {jumpTo('spectator-code-of-conduct')}}>Spectator Code of Conduct</button>
                        <button onClick={(e) => {jumpTo('team-waivers')}}>Team Waivers</button>
                        <button onClick={(e) => {jumpTo('underage-waivers')}}>Underage Waivers</button>
                        <button onClick={(e) => {jumpTo('team-fees')}}>Team Fees</button>
                        <button onClick={(e) => {jumpTo('payments')}}>Payments</button>
                        <button onClick={(e) => {jumpTo('player-eligibility')}}>Player Elegibility</button>
                        <button onClick={(e) => {jumpTo('scheduling')}}>Scheduling</button>
                    </div>
                </div>
            </div>
            <div className='rule-container'>
                <div className='game-rules' id='game-rules'>
                    <h2 className='rule-title'>Game Rules</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Game officials are authorized to give technicals and kick out players.</p>
                        <p>Playing time: 4x10 minutes</p>
                        <p>5 minutes Over time (OT)</p>
                        <p>Shot Clock - 24 seconds after offensive rebound: 14 seconds</p>

                        <div className='time-out-rules'>
                            <p>Time-outs:</p>
                            <p>1 each period</p>
                            <p>2 in the fourth period</p>
                        </div>

                        <div className='foul-rules'>
                            <p>Individual Foul - Foul out on 5 (Personal and Technical)</p>
                            <p>Technical Foul - 1 free throw and possession of the ball at centre</p>
                        </div>
                    </div>
                </div>

                <div className='house-rules' id='house-rules'>
                    <h2 className='rule-title'>House Rules</h2>
                    <Divider />
                    <div className='rule-details'>
                        <div className='fifteen-minute-rule'>
                            <p>Teams must show up and be ready 15 minutes prior to their game. </p>
                        </div>
                        <div className='default/loss-rule'>
                            <p>There will be a grace period of 10 minutes. </p>
                            <p>A team will receive a default/loss if they do not have at least 5 players within the 10 minute grace period. </p>
                            <p>If both teams don't have enough players, a double default will occur. </p>
                        </div>

                        <div className='changed-rule'>
                            <p>Players must be changed and in the gym to qualify for their game. </p>
                            <p>NO EXCEPTIONS. </p>
                        </div>

                        <div className='ready-to-play-rule'>
                            <p>Being in the parking lot or on the way does not qualify as being ready to play. </p>
                        </div>
                    </div>
                </div>

                <div className='gym-rules' id='gym-rules'>
                    <h2 className='rule-title'>Gym Rules</h2>
                    <Divider />
                    <div className='rule-details'>
                    <div className='clean-rule'>
                        <p>Clean up after yourself. For example: </p>
                        <div className='clean-examples'>
                            <p>Wrappers</p>
                            <p>Cans</p>
                            <p>etc.</p>
                        </div>
                    </div>
                    <p>Please no pets, please and thank you. </p>
                    </div>
                </div>

                <div className='jersey-rules' id='jersey-rules'>
                    <h2 className='rule-title'>Jersey Rules</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>If you get our free jerseys, you have to wear them for the rest of the season.</p>
                        <p>No exceptions, game may result in a forfeit.</p>
                    </div>
                </div>

                <div className='spectator-code-of-conduct' id='spectator-code-of-conduct'>
                    <h2 className='rule-title'>Spectator Code of Conduct</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Display good sportsmanship. Always respect players, coaches, and officials. </p>
                        <p>Act appropriately; do not taunt or disturb other fans, enjoy the game together. </p>
                        <p>Cheer good plays of all participants; avoid booing opponent. </p>
                        <div className='cheering-rule'>
                            <p>Cheer in a positive manner and encourage fair play. </p>
                            <p>Profanity and objectionable cheers or gestures are offensive. </p>
                        </div>
                        <p>Help provide a safe and fun environment; throwing any items on the court can cause injury to players and officials. </p>
                        <p>Support the referees and coaches by trusting their judgment and integrity. </p>
                        <p>Be responsible for your own safety - be alert to prevent accidents from flying balls and other avoidable situations. </p>
                        <p>Respect coaches and officials. </p>
                        <p>Be supportive after the game — win or lose. Recognize good effort, teamwork, and sportsmanship. </p>
                        <p>Spectators may respectfully inform table officials and referees in a polite manner. </p>
                    </div>
                </div>

                <div className='team-waivers' id='team-waivers'>
                    <h2 className='rule-title'>Team Waivers</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Team waivers must be completed, signed, and dated by each and every player — not just by one player prior to the first game. </p>
                    </div>
                </div>

                <div className='underage-waivers' id='underage-waivers'>
                    <h2 className='rule-title'>Underage Waivers</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Players underage must have a completed parent guardian form signed before the game. </p>
                    </div>
                </div>

                <div className='team-fees' id='team-fees'>
                    <h2 className='rule-title'>Team Fees</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Team fee: $1,500. </p>
                        <p>All jersey sets come with 14 pieces of jerseys. </p>
                        <p>Additional jerseys may be purchased at availability at $25 each. </p>
                    </div>
                </div>

                <div className='payments' id='payments'>
                    <h2 className='rule-title'>Payments</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>All payments must be completed by the 3rd game. </p>
                        <p>At least 1/3 of the payment must be paid every week. </p>
                    </div>
                </div>

                <div className='player-eligibility' id='player-eligibility'>
                    <h2 className='rule-title'>Player Eligibility</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>Every player must play one of the first 3 games in order to qualify for the rest of the season. </p>
                    </div>
                </div>

                <div className='scheduling' id='scheduling'>
                    <h2 className='rule-title'>Scheduling</h2>
                    <Divider />
                    <div className='rule-details'>
                        <p>A formal time request can be emailed to psaedm1996@gmail.com</p>
                        <p>Requests may be granted or declined at the discretion of the PSA committee. </p>
                    </div>
                </div>
            </div>
            

            
        </div>
    )
}

export default Rules;