// react-router-dom
import { BrowserRouter as Router } from 'react-router-dom';
// Bootstrap styling
import 'bootstrap/dist/css/bootstrap.min.css';
// Components
import Routes from './js/components/routes.js';
import PSANavbar from './js/components/navbar.js';
import Footer from './js/components/footer.js';
// Styling
import './styles/reset.scss';
import './styles/app.scss';


function App() {

  return (
    <Router>
      <div className="App">
        <div className="content">
          <PSANavbar />
          <Routes />
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
