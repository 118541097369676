import { useState, useEffect, useReducer } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './../../firebase';
import { addDoc } from 'firebase/firestore';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
// Datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// React Bootstrap
import Alert from '@mui/material/Alert';
// Waiver imports
import teamWaiver from './../../assets/waivers/psa-waiver.pdf';
import underageWaiver from './../../assets/waivers/psa-underage-waiver.pdf';
// Icons
import { ReactComponent as AddPlayerIcon } from './../../assets/icons/add-player.svg';
// Styling
import './../../styles/register.scss';

function Register () {
    // Determines what is shown on the page
    // If user has yet to submit a team, show registration form
    // If user has submitted, show 'submit successful' screen
    const [userSubmitted, setUserSubmitted] = useState(false);
    const [section, setSection] = useState('team');

    // Variable to force update for birthdates
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    // Alert functionality variables
    const [alertVisibility, setAlertVisability] = useState(false);
    // const [alertIssues, setAlertIssues] = useState([]);

    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState('');
    const [teamName, setTeamName] = useState('');

    const [leaderFirstName, setLeaderFirstName] = useState('');
    const [leaderLastName, setLeaderLastName] = useState('');
    const [leaderBirthday, setLeaderBirthday] = useState(new moment());
    const [leaderBirthdayChanged, setLeaderBirthdayChanged] = useState(false);
    const [leaderEmail, setLeaderEmail] = useState('');
    const [leaderPhoneNumber, setLeaderPhoneNumber] = useState('');
    // const [leaderContactType, setLeaderContactType] = useState('');
    // const [leaderContactInfo, setLeaderContactInfo] = useState('');

    // Initialize players for rendering player info inputs
    const [players, setPlayers] = useState([{}, {}, {}, {}]);
    // variables for setting player details
    const [firstNames, setFirstNames] = useState(["", "", "", "",]);
    const [lastNames, setLastNames] = useState(["", "", "", "",]);
    let tempDate = new moment();
    const [birthdays, setBirthdays] = useState([tempDate, tempDate, tempDate, tempDate]);
    const [phoneNumbers, setPhoneNumbers] = useState(["", "", "", "",]);
    const [birthdaysChanged, setBirthdaysChanged] = useState([false, false, false, false]);
    const [minorsOnTeam, setMinorsOnTeam] = useState(false);

    const [playerCount, setPlayerCount] = useState(4);
    const maxPlayers = 15;
    const [maxPlayersReached, setMaxPlayersReached] = useState(false);

    const handleSectionChange = (event, newValue) => {
        setSection(newValue);
    }
    
    useEffect(() => {
        // Retrieve seasons
        const getSeasons = async () => {
            const seasonCollectionRef = collection(db, "seasons");
            const data = await getDocs(seasonCollectionRef);
            setSeasons(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getSeasons();
    }, []);

    // Check if playerCount has reached maxPlayers
    // If so, set maxPlayersReached to true so that
    // Users cannot try to add any more players
    useEffect(() => {
        if (playerCount + 1 >= maxPlayers) {
            setMaxPlayersReached(true);
        }
    }, [playerCount]);

    function addPlayer() {
        if(playerCount + 1 < maxPlayers) {
            let tempPlayerCount = playerCount + 1;

            setPlayers([...players, {}]);
            setFirstNames([...firstNames, ""]);
            setLastNames([...lastNames, ""]);
            setBirthdays([...birthdays, tempDate]);
            setBirthdaysChanged([...birthdaysChanged, false]);
            setPhoneNumbers([...phoneNumbers], '');
            setPlayerCount(tempPlayerCount);
        }
    }

    function removePlayer (index) {
        let tempPlayerCount = playerCount - 1;

        setPlayers([
            ...players.slice(0, index),
            ...players.slice(index + 1),
        ]);
        setFirstNames([
            ...firstNames.slice(0, index),
            ...firstNames.slice(index + 1),
        ]);
        setLastNames([
            ...lastNames.slice(0, index),
            ...lastNames.slice(index + 1),
        ]);
        setBirthdays([
            ...birthdays.slice(0, index),
            ...birthdays.slice(index + 1),
        ]);
        setBirthdaysChanged([
            ...birthdaysChanged.slice(0, index),
            ...birthdaysChanged.slice(index + 1),
        ]);
        setPhoneNumbers([
            ...phoneNumbers.slice(0, index),
            ...phoneNumbers.slice(index + 1),
        ]);
        setPlayerCount(tempPlayerCount);
    }

    function setPlayerBirthdate(date, index) {
        let currentBirthdays = birthdays;
        let currentBirthdaysChanged = birthdaysChanged
        currentBirthdays[index] = moment(date);
        currentBirthdaysChanged[index] = true;
        setBirthdays(currentBirthdays);
        setBirthdaysChanged(currentBirthdaysChanged);
        forceUpdate();
    }

    function setFirstName (updatedFirstName, index) {
        let currentFirstNames = firstNames;
        currentFirstNames[index] = updatedFirstName;
        setFirstNames(currentFirstNames);
    }

    function setLastName (updatedLastName, index) {
        let currentLastNames = lastNames;
        currentLastNames[index] = updatedLastName;
        setLastNames(currentLastNames);
    }

    function setPhoneNumber (updatedPhoneNumber, index) {
        let currentPhoneNumbers = phoneNumbers;
        currentPhoneNumbers[index] = updatedPhoneNumber;
        setPhoneNumbers(currentPhoneNumbers);
    }

    

    const PlayerInputs = (props) => {
        return (
            <div className='player-input-section'>
                <h3>Player {props.player + 2}</h3>
                {playerCount > 4 ?
                    <button className='remove-player-button' onClick={(e) => {removePlayer(props.player)}} disabled={maxPlayersReached}>
                        <p>Remove player</p>
                    </button>
                    :
                    <></>
                }
                
                <div className='name-section'>
                    <h4>Name</h4>
                    <div className='name-inputs'>
                        <input
                            placeholder='First Name'
                            onChange={(e) => {setFirstName(e.target.value, props.player)}}
                            defaultValue={firstNames[props.player]}
                        />
                        <input
                            placeholder='Last Name'
                            onChange={(e) => {setLastName(e.target.value, props.player)}}
                            defaultValue={lastNames[props.player]}
                        />
                    </div>
                </div>
                <div className='birthdate-section'>
                    <h4>Birthday</h4>
                    <DatePicker
                        className='birthdate-input'
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={90}
                        maxDate={moment().toDate()}
                        selected={moment(birthdays[props.player]).toDate()}
                        onChange={(date) => {
                            setPlayerBirthdate(date, props.player);
                        }}
                        dateFormat="MMMM d, yyyy"
                        popperPlacement='top'
                    />
                </div>
                <div className='phone-number-section'>
                    <h4>Phone Number (Text)</h4>
                    <input
                        placeholder='Phone Number (Text)'
                        onChange={(e) => {setPhoneNumber(e.target.value, props.player)}}
                        defaultValue={phoneNumbers[props.player]}
                    />
                </div>
            </div>
        )
    }

    const submitTeam = async () => {
        let valid = validateRegistration();

        // If any team members are minors, prompt to download waiver and submit in-person
        // Calculate the birthdate of a person who has just turned 18
        let majorityAgeDate = new moment().subtract(18, 'years');
        // Subtract another day
        //      - A person turned 18 18 years ago today, yesterday they would have still been a minor
        majorityAgeDate = majorityAgeDate.subtract(1, 'days');
        
        if (leaderBirthday.isAfter(majorityAgeDate)) {
            setMinorsOnTeam(true);
        }

        birthdays.forEach((playerBirthday) => {
            if (playerBirthday.isAfter(majorityAgeDate)) {
                setMinorsOnTeam(true);
            }
        });

        if (valid) {
            // Save all players' information into players variable and overwrite
            for (let i = 0; i < players.length; i++) {
                let isMinor = false;
                if (birthdays[i].isAfter(majorityAgeDate)) {
                    isMinor = true;
                    setMinorsOnTeam(true);
                }

                let playerInfo = {
                    firstname: firstNames[i],
                    lastname: lastNames[i],
                    birthday: birthdays[i].format(),
                    phone: phoneNumbers[i],
                    minor: isMinor,
                    leader: false,
                    eligibility0: false,
                    eligibility1: false,
                    eligibility2: false,
                }

                let currentPlayers = players;
                currentPlayers[i] = playerInfo;
                setPlayers(currentPlayers);
            }

            // Create new player object for saving purposes
            let finalPlayerList = players;
            // Append team leader's information to player object
            finalPlayerList = [...finalPlayerList, {
                firstname: leaderFirstName,
                lastname: leaderLastName,
                birthday: leaderBirthday.format(),
                leader: true,
                // contacttype: leaderContactType,
                // contactinfo: leaderContactInfo,
                email: leaderEmail,
                phone: leaderPhoneNumber,
                minor: (leaderBirthday.isAfter(majorityAgeDate)),
            }];

            // Saving to firestore
            await addDoc(collection(db, "registrations"), {
                teamname: teamName,
                season: selectedSeason,
                players: finalPlayerList,
                hasminors: minorsOnTeam,
            });

            setUserSubmitted(true);
        }

        // Scroll to top so user can view alert
        setAlertVisability(true);
        window.scrollTo(0,0);
    }

    const validateRegistration = () => {
        let valid = true;
        let firstNamesValid = true;
        let lastNamesValid = true;
        let birthdaysValid = true;
        let phoneNumbersValid = true;
        // let tempIssues = [];

        // Check if a season was selected
        if (selectedSeason === '') {
            valid = false;
            // tempIssues = alertIssues;
            // setAlertIssues([...tempIssues, "A season was not selected"]);
        }

        // Check if team name was entered
        if (teamName === '') {
            valid = false;
            // tempIssues = alertIssues;
            // setAlertIssues([...tempIssues, "No team name was entered"]);
        }

        // Check names
        if (leaderFirstName === '') {
            valid = false;
            // tempIssues = alertIssues;
            // setAlertIssues([...tempIssues, "Missing team leader's first name"]);
        }
        
        if (leaderLastName === '') {
            valid = false;
            // tempIssues = alertIssues;
            // setAlertIssues([...tempIssues, "Missing team leader's last name"]);
        }

        firstNames.forEach((name) => {
            if (name === '' && firstNamesValid) {
                valid = false;
                firstNamesValid = false;
                // tempIssues = alertIssues;
                // setAlertIssues([...tempIssues, "One or more players have missing first names"]);
            }
        });

        lastNames.forEach((name) => {
            if (name === '' && lastNamesValid) {
                valid = false;
                lastNamesValid = false;
                // tempIssues = alertIssues;
                // setAlertIssues([...tempIssues, "One or more players have missing last names"]);
            }
        });

        // Contact information

        phoneNumbers.forEach((number) => {
            if (number === '' && phoneNumbersValid) {
                valid = false;
                phoneNumbersValid = false;
            }
        })

        if (leaderEmail === '') {
            valid = false;
        }

        if (leaderPhoneNumber === '') {
            valid = false;
        }

        // // Check if contact method and contact information was entered
        // if (leaderContactType === '') {
        //     valid = false;
        //     // tempIssues = alertIssues;
        //     // setAlertIssues([...tempIssues, "Team leader's contact method was not entered"]);
        // }
        // else if (leaderContactType !== '' && leaderContactInfo === '') {
        //     valid = false;
        //     // tempIssues = alertIssues;
        //     // setAlertIssues([...tempIssues, "Team leader's contact information was not entered"]);
        // }

        // Check if birthdates have been entered
        if (leaderBirthdayChanged === false) {
            valid = false;
            // tempIssues = alertIssues;
            // setAlertIssues([...tempIssues, "Team leader's birthday was not entered"]);
        }

        birthdaysChanged.forEach((birthdayChanged) => {
            if (birthdayChanged === false && birthdaysValid) {
                valid = false;
                birthdaysValid = false;
                // tempIssues = alertIssues;
                // setAlertIssues([...tempIssues, "One or more players' birthdays have not been entered"]);
            }
        });

        return valid;
    }

    return (
        <div className='register-page'>
                <h1 className='page-title'>Team Registration</h1>
                {/* Change display depending on user has submitted a team */}
                { !userSubmitted ?
                    <div className='register-display'>
                        <div className='registration-text'>
                            <p>Want to join PSA? Enter your team information and the season you want to play here.</p>
                            <div className='waiver-info'>
                                <p>Your team MUST fill out and submit our waiver of liability to participate. You can download it <a href={teamWaiver} target='_blank' rel="noreferrer">here</a>.</p>
                                <p>
                                    Additionally, if your team has any underaged players, their parents/guardians must
                                    fill out our underage liability waiver which can be downloaded <a href={underageWaiver} target='_blank' rel="noreferrer">here</a>.
                                </p>
                                <p>Already signed up and need to submit waiver(s)? <Link to='/waiver-upload'>Click me!</Link></p>
                            </div>
                        </div>

                        <div className='register-section-selection'>
                            <Tabs
                                value={section}
                                onChange={handleSectionChange}
                                aria-label="register section selector"
                                sx={{width: '100%', maxWidth: '25rem', margin: '0 auto'}}
                            >
                                <Tab
                                    value="team"
                                    label="Team Info"
                                />
                                <Tab
                                    value="players"
                                    label="Player Info"
                                />
                            </Tabs>
                        </div>

                        {alertVisibility ? 
                            <Alert severity='error'>
                                <p className='alert-message-1'>Could not submit team</p>
                                <p className='alert-message-2'>Please ensure all fields have been entered properly before trying again</p>
                            </Alert>
                            :
                            <></>
                        }
                        

                        <div className='user-input'>
                            {section === 'team'?
                                <div className='team-details'>
                                    <div className='team-name-section'>
                                        <input
                                            className='team-name-input'
                                            placeholder='Team Name'
                                            value={teamName}
                                            onChange={(e) => {setTeamName(e.target.value)}}
                                        />
                                        <Divider />
                                        <label>Team Name</label>
                                    </div>

                                    <div className='season-section'>
                                        <select
                                            value={selectedSeason}
                                            onChange={(e) => {setSelectedSeason(e.target.value)}}>
                                            <option value=''>Select season</option>
                                            {seasons.map((season) => {
                                                return (
                                                    <option
                                                        key={season.name}
                                                        value={season.name}>
                                                        {season.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                :
                                <div className='player-details'>
                                    {/* Team Leader */}
                                    <div className='leader-section'>
                                        <h2>Team Leader</h2>
                                        <div className='team-leader-inputs'>
                                            <div className='name-section'>
                                                <h4>Name</h4>
                                                <div className='name-inputs'>
                                                    <input
                                                        placeholder='First Name'
                                                        onChange={(e) => {setLeaderFirstName(e.target.value)}}
                                                        defaultValue={leaderFirstName}
                                                    />
                                                    <input
                                                        placeholder='Last Name'
                                                        onChange={(e) => {setLeaderLastName(e.target.value)}}
                                                        defaultValue={leaderLastName}
                                                    />
                                                </div>
                                            </div>
                                            <div className='birthday-section'>
                                                <h4>Birthday</h4>
                                                <DatePicker
                                                    className='birthdate-input'
                                                    selected={leaderBirthday.toDate()}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={90}
                                                    maxDate={moment().toDate()}
                                                    onChange={(date) => {
                                                        setLeaderBirthday(moment(date));
                                                        setLeaderBirthdayChanged(true);
                                                    }}
                                                    dateFormat="MMMM d, yyyy"
                                                    popperPlacement='top'
                                                />
                                            </div>
                                            <div className='email-section'>
                                                <h4>Email</h4>
                                                <input
                                                    placeholder='Email'
                                                    onChange={(e) => {setLeaderEmail(e.target.value)}}
                                                    defaultValue={leaderEmail}
                                                />
                                            </div>
                                            <div className='phone-number-section'>
                                            <h4>Phone Number (Text)</h4>
                                                <input
                                                    placeholder='Phone Number (Text)'
                                                    onChange={(e) => {setLeaderPhoneNumber(e.target.value)}}
                                                    defaultValue={leaderPhoneNumber}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Players */}
                                    <div className='players-section'>
                                        <h2>Players</h2>
                                        {players.map((player, index) => {
                                            return (
                                                <PlayerInputs
                                                    key={index}
                                                    player={index}
                                                />
                                            )
                                        })}
                                    </div>

                                    <button className='add-player-button' onClick={addPlayer} disabled={maxPlayersReached}>
                                        <AddPlayerIcon className='add-player-icon' />
                                        <p>Add another player</p>
                                    </button>

                                    {maxPlayersReached ?
                                    <p className='max-players-message'>Maximum number of players reached!</p>
                                    :
                                    <></>}
                                </div>
                            }
                        </div>
                        <button className='submit-team-button' onClick={submitTeam}>Submit Team</button>
                    </div>

                    :

                    // When user successfully submits a team
                    <div className='submitted-display'>
                        <h2>Team "{teamName}" submitted successfully!</h2>

                        <p>
                            Please ensure that all players sign and submit the <a href={teamWaiver} target='_blank' rel="noreferrer">PSA Waiver of Liability</a>.
                        </p>

                        {minorsOnTeam ?
                            <p>
                                <span className='important-alert'>IMPORTANT:</span> The team you submitted has players that are under 18. For them to play, they must have
                                a parent/guardian sign and submit the <a href={underageWaiver} target='_blank' rel="noreferrer">PSA Underage Liability Waiver</a>.
                            </p>
                            :
                            <></>
                        }

                        {minorsOnTeam ? 
                            <p>
                                You can submit your team's completed waivers <Link to='/waiver-upload'>here</Link>.
                            </p>
                            :
                            <p>
                                You can submit your team's completed waiver <Link to='/waiver-upload'>here</Link>.
                            </p>
                        }
                    </div>
                }
                
        </div>
    )
}

export default Register;