import { useState, useEffect } from 'react';
import { auth, logInWithEmailAndPassword } from './../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// navigation
import { useNavigate } from 'react-router-dom';
// Styling
import '../../styles/login.scss'

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    // check to see if user is logged in
    useEffect(() => {
        if (loading) {
            // loading screen here?
            return;
        }
        if (user) {
            navigate('/dashboard');
        }
    }, [user, loading]);

    const loginFunction = (e) => {
        e.preventDefault();
        logInWithEmailAndPassword(email, password);
    }

    return (
        <div className='login-page'>
            <h1 className='page-title'>Login</h1>

            <form className='login-form' onSubmit={loginFunction}>
                <div className='email-section'>
                    <label>Email</label>
                    <input
                        type="text"
                        className="email-textbox"
                        value={email}
                        onChange={
                            (e) => {
                                setEmail(e.target.value)
                            }
                        }>
                    </input>
                </div>
                <div className='password-section'>
                    <label>Password</label>
                    <input
                        type="password"
                        className="password-textbox"
                        value={password}
                        onChange={
                            (e) => {
                                setPassword(e.target.value);
                            }
                        }>
                    </input>
                </div>
                <input type="submit" value="Login" className='submit-button'/>
            </form>
        </div>
    )
}

export default Login;