import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "./../../../firebase";
import { collection, getDocs, query, orderBy, doc, deleteDoc } from "firebase/firestore";
// Components
import AnnouncementCard from "./../../components/announcementCard";

function ManageAnnouncements() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    const [announcements, setAnnouncements] = useState([]);
    // Retrieve announcements from firestore database
    useEffect(() => {

        const getAnnouncements = async () => {
            const announcementCollectionRef = collection(db, "announcements");
            const data = await getDocs(query(announcementCollectionRef, orderBy("datepublished")));
            setAnnouncements(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getAnnouncements();
    }, []);

    const deleteAll = async () => {
        if(announcements.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to delete all announcements? This cannot be undone")) {
                for(let announcement of announcements) {
                    await deleteDoc(doc(db, "announcements", announcement.id));
                }
                alert("All announcements deleted successfully");

                // Refresh the page
                navigate(0);
            }
        }
        else {
            alert("No announcements exist");
        }
    }

    return (
        <div className="manage manage-announcements-page">
            <h1 className="page-title">Manage Announcements</h1>

            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
            <Link className="add-link" to='/edit-announcement'>Add new announcement</Link>

            {announcements.length < 1 ? <h2>No announcements at the moment. </h2> : <></>}
            {announcements.map((announcement) => {
                return (
                    <div className="announcement" key={announcement.id}>
                        {announcement.dateupdated === undefined ? 
                            <AnnouncementCard
                                announcement={announcement}
                                title={announcement.title}
                                body={announcement.body}
                                datepublished={announcement.datepublished}
                                admin/> : 
                            <AnnouncementCard
                                announcement={announcement}
                                title={announcement.title}
                                body={announcement.body}
                                datepublished={announcement.datepublished}
                                dateupdated={announcement.dateupdated}
                                admin/>
                        }
                    </div>
                )
            })}

            <button className="delete-all-button" onClick={deleteAll}>Delete all announcements</button>
        </div>
    )
}

export default ManageAnnouncements;