import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
// Styling
import './../../styles/navbar.scss';
// Components
import { ReactComponent as PSALogo } from './../../assets/icons/psa_logo.svg';

const navItems = [
  {title: 'Announcements', path: '/announcements'},
  {title: 'Games', path: '/games'},
  {title: 'Standings', path: '/standings'},
  {title: 'Rules', path: '/rules'},
  {title: 'Register', path: '/register'},
  {title: 'Gallery', path: '/gallery'},
  {title: 'Contact', path: '/contact'},
];

function PSANavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center' }}
      className='drawer'
    >
      <Link to='/'><PSALogo className='drawer-logo' /></Link>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton className='drawer-button' sx={{ textAlign: 'center', fontWeight: '600' }}>
              <Link to={item.path} className='nav-link-dropdown'>{item.title}</Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{boxShadow: 'none'}}>
        <Toolbar className='navbar'
          sx={{ flexDirection: {xs: 'row-reverse', md: 'row'}}}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: '#000', display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to='/'><PSALogo className='logo' /></Link>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {navItems.map((item) => (
              <Link to={item.path} className='nav-link'>{item.title}</Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
            anchor='top'
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
        >
            {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default PSANavbar;
