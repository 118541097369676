import { useState } from 'react';
import moment from 'moment';

function GameCard (props) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className='game-card'>
            <button className='game-display' onClick={() => setShowDetails(!showDetails)}>
                <h3 className='game-title'>
                {props.completed && props.teamonescore >= props.teamtwoscore ? <p className='game-win title-team-one'>{props.teamone}</p> : <p className='title-team-one'>{props.teamone}</p>}
                <p className='vs'>vs.</p>
                {props.completed && props.teamtwoscore >= props.teamonescore ? <p className='game-win title-team-two'>{props.teamtwo}</p> : <p className='title-team-two'>{props.teamtwo}</p>}
                </h3>
                {props.completed ? <h4 className='game-score'>
                    {props.teamonescore > props.teamtwoscore ? <p className='score-component game-win'>{props.teamonescore}</p> : <p className='score-component'>{props.teamonescore}</p>}
                    <p className='score-component'>-</p>
                     {props.teamtwoscore > props.teamonescore ? <p className='score-component game-win'>{props.teamtwoscore}</p> : <p className='score-component'>{props.teamtwoscore}</p>}
                </h4> : <></>}
                {showDetails ? <p className='show-hide-details'>Hide details</p> : <p className='show-hide-details'>Show details</p>}
            </button>
            {!showDetails ? <></> : 
                <div className='game-details'>
                <p>{props.division}</p>
                <p>{moment(props.datetime).format('h:mm A')}</p>
                <p>Gym: {props.gym}</p>
                </div>
            }
        </div>
    )
}

export default GameCard;