import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
// Tooltip imports
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';

function StandingsTable(props) {
    return (
        <div className='standings-table-container'>
            {props.teams.length < 1 ? <h3>There are no teams in this division right now. </h3> :
                <TableContainer component={Paper}>
                    <Table aria-label='standings table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team</TableCell>
                                <TableCell align='right'>GP</TableCell>
                                <TableCell align='right'>W</TableCell>
                                <TableCell align='right'>L</TableCell>
                                <TableCell align='right'>PF</TableCell>
                                <TableCell align='right'>PA</TableCell>
                                <TableCell align='right'>GD</TableCell>
                                <TableCell align='right'>PTS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {props.teams.map((team) => (
                            team.division === props.division.name ? 
                            <TableRow
                                key={team.name}
                                sx={{ '&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component='th' scope='row'>{team.name}</TableCell>
                                <TableCell align='right'>{team.gamesplayed}</TableCell>
                                <TableCell align='right'>{team.wins}</TableCell>
                                <TableCell align='right'>{team.losses}</TableCell>
                                <TableCell align='right'>{team.pf}</TableCell>
                                <TableCell align='right'>{team.pa}</TableCell>
                                <TableCell align='right'>{team.gd}</TableCell>
                                <TableCell align='right'>{team.points}</TableCell>
                            </TableRow>
                            : <></>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            }
            
        </div>
  );
}

export default StandingsTable;