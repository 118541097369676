import ImageModal from "./imageModal";
// Styling
import '../../styles/imageGallery.scss';

function ImageGallery(props) {
    return (
        <div className='image-gallery'>
            {props.array.sort((a, b) => a.filename < b.filename ? 1 : -1).map((item) => {
                return (
                    <ImageModal
                        imageURL={item.url}
                        filename={item.filename}
                        refLocation={props.refLocation}
                        admin={props.admin}
                        key={item.filename}
                    />
                )
            })}
        </div>
    )
}

export default ImageGallery;