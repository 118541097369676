import { useState, useEffect } from 'react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Components
import GameDisplay from '../components/gameDisplay';
// Styling
import './../../styles/games.scss'

function Games() {
    const [upcomingGames, setUpcomingGames] = useState([]);
    const [completedGames, setCompletedGames] = useState([]);
    const [gameType, setGameType] = useState("upcoming");
    const handleChange = (event, newValue) => {
        setGameType(newValue);
    }

    useEffect(() => {
        const getGames = async () => {
            const gamesCollectionRef = collection(db, 'games');
            const upcomingData = await getDocs(query(gamesCollectionRef, orderBy("datetime"), where("gamecomplete", "==", false)));
            setUpcomingGames(upcomingData.docs.map((doc) => ({...doc.data(), id: doc.id})));
            const completedData = await getDocs(query(gamesCollectionRef, orderBy("datetime", "desc"), where("gamecomplete", "==", true)));
            setCompletedGames(completedData.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getGames();
    }, []);

    return (
        <div className='games-page'>
            <h1 className='page-title'>Games</h1>
            
            <div className='game-type-selection'>
                <h2 className='game-type-prompt'>Select game type</h2>
                <Tabs
                    value={gameType}
                    onChange={handleChange}
                    aria-label="game type selector"
                    sx={{width: '100%', maxWidth: '25rem', margin: '0 auto'}}
                >
                    <Tab
                        value="upcoming"
                        label="Upcoming"
                    />
                    <Tab
                        value="completed"
                        label="Completed"
                    />
                </Tabs>
            </div>
            
            {gameType === "upcoming" ? <GameDisplay games={upcomingGames} completed={false}/> : <GameDisplay games={completedGames} completed={true}/>}
        </div>
    )
}

export default Games;