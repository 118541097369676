import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { auth, db } from "./../../../firebase";
import { collection, doc, addDoc, setDoc, deleteDoc } from "firebase/firestore";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import Alert from 'react-bootstrap/Alert';

function EditAnnouncement() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    const location = useLocation();
    // variables for Firestore
    const [announcementId, setAnnouncementId] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [datePublished, setDatePublished] = useState(new moment());
    // variables for alert message
    const [messageDisplay, setMessageDisplay] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [messageText, setMessageText] = useState('');

    let announcement;
    useEffect(() => {
        if  (location.state !== null) {
            announcement = location.state;
            setAnnouncementId(announcement.id);
            setTitle(announcement.title);
            setBody(announcement.body);
            setDatePublished(moment(announcement.datepublished));
        }
    }, [])

    const saveAnnouncement = async () => {
        // Check for a title and body before saving
        if (title !== '' || body !== '') {
            // If updating an announcement
            if (announcementId !== '') {
                await setDoc(doc(db, "announcements", announcementId), {
                    title: title,
                    body: body,
                    datepublished: datePublished.format(),
                    dateupdated: moment().format(),
                });
            }
            else {
                // If saving a new announcement
                const docRef = await addDoc(collection(db, "announcements"), {
                    title: title,
                    body: body,
                    datepublished: moment().format(),
                });
                setAnnouncementId(docRef.id);
            }

            setMessageDisplay(true);
            setMessageType('success');
            setMessageText('Announcement saved successfully');
        }
        else {
            setMessageDisplay(true);
            setMessageType('warning');
            setMessageText('Could not save announcement, required fields are missing');
        }

        window.scrollTo(0,0);
    }

    const clearFields = () => {
        setAnnouncementId('');
        setTitle('');
        setBody('');

        setMessageDisplay(false);
        window.scrollTo(0,0);
    }

    const deleteAnnouncement = async () => {
        if (announcementId !== '') {
            await deleteDoc(doc(db, "announcements", announcementId));
            clearFields();

            setMessageDisplay(true);
            setMessageType('success');
            setMessageText('Announcement deleted successfully');
        }
        else {
            setMessageDisplay(true);
            setMessageType('warning');
            setMessageText('Announcement does not exist, cannot delete');
        }
        window.scrollTo(0,0);
    }

    return (
        <div className="edit edit-announcement-page">
            <h1 className="page-title">Edit Announcement</h1>
            
            <Link className="management-link" to='/manage-announcements'>Back to Announcement Management Page</Link>

            {messageDisplay ? <Alert variant={messageType}>{messageText}</Alert> : <></>}
            <div className="user-input title-section">
                <label>Title</label>
                <input
                    placeholder="Enter title here"
                    value={title}
                    onChange={(e) => {setTitle(e.target.value)}}
                />
            </div>
            <MDEditor
                className='announcement-editor'
                value={body}
                onChange={setBody}
            />

            <div className="button-container">
                <button className="save-button" onClick={saveAnnouncement}>Save</button>
                <button className="clear-button" onClick={clearFields}>Clear</button>
                <button className="delete-button" onClick={deleteAnnouncement}>Delete</button>
            </div>
        </div>
    )
}

export default EditAnnouncement;