import { useState, useEffect } from "react";
import { storage } from "./../../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
// Components
import ImageGallery from "../components/imageGallery";

function Gallery() {
    const imageListRef = ref(storage, "images/");
    const[imageArray, setImageArray] = useState([]);

    // Get all images for display
    useEffect(() => {
        listAll(imageListRef).then((response) => {
            response.items.forEach((image) => {
                getDownloadURL(image).then((url) => {
                    setImageArray((current) => [...current, {
                        url: url,
                        filename: image.name,
                    }])
                });
            });
        });
    }, []);

    return (
        <div className="gallery-page">
            <h1 className="page-title">Gallery</h1>
            {imageArray.length < 1 ? <h3 className="no-images">There are no images in the gallery yet. </h3> :
                <ImageGallery array={imageArray} admin={false} refLocation="images"/>
            }
        </div>
    )
}

export default Gallery;