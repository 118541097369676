import { useEffect, useState, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
// Firebase
import {db} from './../../../firebase.js'
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { auth } from "./../../../firebase";
// Table
import MaterialReactTable from 'material-react-table';


function ManageTeams() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Create useState instance of an empty array to be populated with the teams
    const [teams, setTeams] = useState([]);
    
    // Retrieve teams from firestore database
    useEffect(() => {

        const getTeams = async () => {
            const teamsCollectionRef = collection(db, "teams");
            const data = await getDocs(teamsCollectionRef);
            setTeams(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
        }

        getTeams();
    }, []);

    // Columns for table
    const columns = useMemo(
        () => [
            {
                header: 'Team',
                accessorFn: (row) => row.name,
                Cell: ({cell}) => (
                    <Link to='/edit-team' state={cell.row.original}>{cell.row.original.name}</Link>
                ),
            },
            {
                header: 'Division',
                accessorFn: (row) => row.division,
            },
            {
                header: 'Games Played',
                accessorFn: (row) => row.gamesplayed,
            },
            {
                header: 'Wins',
                accessorFn: (row) => row.wins,
            },
            {
                header: 'Losses',
                accessorFn: (row) => row.losses,
            },
            {
                header: 'Points For',
                accessorFn: (row) => row.pf,
            },
            {
                header: 'Points Against',
                accessorFn: (row) => row.pa,
            },
            {
                header: 'Game Difference',
                accessorFn: (row) => row.gd,
            },
            {
                header: 'Points',
                accessorFn: (row) => row.points,
            },
            {
                header: 'Players',
                accessorFn: (row) => {
                    let playerNames = '';
                    if (row.players > 0) {
                        row.players.forEach((player) => {
                            playerNames += `${player.firstname} ${player.lastname}, `
                        });
                        return playerNames;
                    }
                    else {
                        return 'No player information listed';
                    }
                }
            },
        ],
        [],
    );

    const deleteAll = async () => {
        if(teams.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to delete all teams? This cannot be undone")) {
                for(let team of teams) {
                    await deleteDoc(doc(db, "teams", team.id));
                }
                alert("All teams deleted successfully");

                // Refresh the page
                navigate(0);
            }
        }
        else {
            alert("No teams exist");
        }
    }

    return (
        <div className="manage manage-teams-page">
            <h1 className="page-title">Manage Teams</h1>
            
            <Link className="dashboard-link" to='/dashboard'>Back to Dashboard</Link>
            <Link className="add-link" to='/edit-team'>Add new team</Link>

            {/* Table of teams */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={teams}
                />
            </div>

            <button className="delete-all-button" onClick={deleteAll}>Delete all teams</button>
        </div>
    )
}

export default ManageTeams;