import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useLocation } from 'react-router-dom';
// Firebase imports
import { doc, collection, addDoc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './../../../firebase';
// Bootstrap imports
import Alert from 'react-bootstrap/Alert';

function EditGym() {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Gym name variable
    const [newGym, setNewGym] = useState('');
    const [gymId, setGymId] = useState('');
    const location = useLocation();
    let gym;

    // Post-CRUD gym name variable
    const [updatedGym, setUpdatedGym] = useState('');

    // anonymous function useEffect to prevent infinite looping of setState
    //      - if I try to setNewGym to gym.name outside of useEffect,
    //      an infinite loop occurs
    useEffect(() => {
        if  (location.state !== null) {
            setNewGym(location.state.name);
            setGymId(location.state.id);
        }
    }, [])

    // Alert visibility states
    const [saveAlertState, setSaveAlertState] = useState(false);
    const [deleteAlertState, setDeleteAlertState] = useState(false);
    const [warningAlertState, setWarningAlertState] = useState(false);

        // Clear all fields
    const clearFields = () => {
        setNewGym('');
        setGymId('');

        window.scrollTo(0,0);
    }

    // Click event for when user wants to save their gym
    // const [newGym, setNewGym] = useState('');
    const saveGym = async () => {
        // If gym did exist, overwrite gym name
        if (gymId !== '') {
            await setDoc(doc(db, "gyms", gymId), {
                name: newGym
            });
        }
        // If gym did not previously exist, create new one with unique id
        else {
            const docRef = await addDoc(collection(db, "gyms"), {
                name: newGym
            });
            setGymId(docRef.id);
        }
        
        setUpdatedGym(newGym);
        setSaveAlertState(true);
        clearFields();

        window.scrollTo(0,0);
    }

    // Click event for when user wants to delete the gym
    const deleteGym = async () => {
        // Check to see if gym exists
        if (gymId !== '') {
            // Find specified gym in Firestore
            const docRef = doc(db, "gyms", gymId);
            const docSnap = await getDoc(docRef);

            // If gym exists, delete it and tell user
            if (docSnap.exists()) {
                await deleteDoc(doc(db, "gyms", gymId));
                setUpdatedGym(newGym)
                clearFields();
                setDeleteAlertState(true);
            }
            // If gym DOES NOT exist, do not delete, warn the user it does not exist
            else {
                setWarningAlertState(true);
            }
        }
        else {
            alert('Cannot delete a gym that does not exist');
        }

        window.scrollTo(0,0);
    }

    return (
        <div className='edit edit-gym-page'>
            <Alert variant='success' show={saveAlertState}>Gym "{updatedGym}" saved successfully.</Alert>
            <Alert variant='danger' show={deleteAlertState}>Gym "{updatedGym}" deleted successfully.</Alert>
            <Alert variant='warning' show={warningAlertState}>Gym "{updatedGym}" does not exist.</Alert>

            <h1 className='page-title'>Edit Gym</h1>
            
            <Link className="management-link" to='/manage-gyms'>Back to Gym Management Page</Link>

            {/* CRUD functionality*/}
            <div className='user-input gym-name-section'>
                <label>Name</label>
                <input
                    placeholder="Gym name"
                    value={newGym}
                    onChange={(e) => {setNewGym(e.target.value)}}>
                </input>
            </div>

            <div className='button-container'>
                <button className='save-button' onClick={saveGym}>Save</button>
                <button className='clear-button' onClick={clearFields}>Clear</button>
                <button className='delete-button' onClick={deleteGym}>Delete</button>
            </div>
        </div>
    )
}

export default EditGym;