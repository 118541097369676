import sponsorList from '../data/sponsorList.json';
// Styling
import '../../styles/sponsors.scss'

function Sponsors () {
    return (
        <div className='sponsors-page'>
            <h1 className='page-title'>Sponsors</h1>
            <div className='sponsors-container'>
                <div className='main-sponsors'>
                    {sponsorList.mainSponsors.map((sponsor) => {
                        return <div className='sponsor' key={sponsor.name}>
                            {sponsor.hasLink ?
                                <a href={sponsor.link} target='_blank' rel='noreferrer'>
                                    {sponsor.hasIcon ?
                                        <img src={require(`./../../assets/icons/${sponsor.iconFilename}`)} alt={sponsor.name}/>
                                        :
                                        <>{sponsor.name}</>
                                    }
                                    
                                </a>
                                :
                                <>
                                    {sponsor.hasIcon ?
                                        <img src={require(`./../../assets/icons/${sponsor.iconFilename}`)} alt={sponsor.name}/>
                                        :
                                        <>{sponsor.name}</>
                                    }
                                </>
                            }
                        </div>
                    })}
                </div>
                <div className='secondary-sponsors'>
                    {sponsorList.secondarySponsors.map((sponsor) => {
                            return <div className='sponsor' key={sponsor.name}>
                                {sponsor.hasLink ?
                                    <a href={sponsor.link} target='_blank' rel='noreferrer'>
                                        {sponsor.hasIcon ?
                                            <img src={require(`./../../assets/icons/${sponsor.iconFilename}`)} alt={sponsor.name}/>
                                            :
                                            <>{sponsor.name}</>
                                        }
                                        
                                    </a>
                                    :
                                    <>
                                        {sponsor.hasIcon ?
                                            <img src={require(`./../../assets/icons/${sponsor.iconFilename}`)} alt={sponsor.name}/>
                                            :
                                            <>{sponsor.name}</>
                                        }
                                    </>
                                }
                            </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Sponsors;