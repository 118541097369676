import { useState, useEffect, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage } from './../../../firebase';
import { ref, listAll, getDownloadURL, getStorage, deleteObject } from 'firebase/storage';
import { useNavigate, Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';

function ViewWaivers () {
    // Verify that the user is authenticated to use this page
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    // Obtain all waivers from firebase
    const waiverListRef = ref(storage, "waivers/");
    const[waiverArray, setWaiverArray] = useState([]);

    useEffect(() => {
        // Get all waivers
        listAll(waiverListRef).then((response) => {
            response.items.forEach((waiver) => {
                getDownloadURL(waiver).then((url) => {
                    setWaiverArray((current) => [...current, {
                        url: url,
                        filename: waiver.name,
                    }]);
                });
            });
        });
    }, []);

    const deleteWaiver = async (waiver) => {
        const storage = getStorage();
        const waiverRef = ref(storage, `waivers/${waiver.filename}`)
        
        // eslint-disable-next-line no-restricted-globals
        if(confirm('Are you sure you want to delete this waiver?')) {
            deleteObject(waiverRef).then(() => {
                alert('Waiver deleted successfully.');
                navigate(0);
            }).catch((error) =>{
                alert("Could not delete waiver.");
            })
        }
    }

    // Table columns
    const columns = useMemo(
        () => [
            {
                header: 'File name',
                accessorFn: (row) => row.filename,
                Cell: ({cell}) => (
                    <a className='file-link' href={cell.row.original.url} target="_blank" rel="noreferrer">{cell.row.original.filename}</a>
                ),
            },
            {
                header: 'Date Uploaded',
                accessorFn: (row) => row.filename,
                Cell: ({cell}) => (
                    <p>{moment(cell.row.original.filename.substr(0, 10)).format("MMMM DD YYYY")}</p>
                ),
            },
            {
                header: 'Delete',
                accessorFn: (row) => row,
                Cell: ({cell}) => (
                    <button className='delete-button' onClick={() => deleteWaiver(cell.row.original)}>Delete</button>
                ),
            }
        ],
        []
    );

    return (
        <div className="manage view-waivers-page">
            <h1 className='page-title'>View Waivers</h1>

            <Link className='dashboard-link' to='/dashboard'>Back to Dashboard</Link>

            {/* Table */}
            <div className='data-table'>
                <MaterialReactTable
                    columns={columns}
                    data={waiverArray}
                />
            </div>
        </div>
    )
}

export default ViewWaivers;