import { useState } from 'react';
import moment from 'moment';
import GameCard from './gameCard.js';
// Styling
import './../../styles/gameDisplay.scss';

function GameDisplay(props) {
    const games = props.games;
    const completed = props.completed;
    // const [showDetails, setShowDetails] = useState(false);

    if (games.length === 0) {
      return <h3 className='no-games'>No games yet, check again soon!</h3>
    }
  
  var currentDate = moment(games[0].datetime);
  var newDate = true;

  return (
    <>
      {games.map((game) => {
        if (completed === game.gamecomplete) {
          // For displaying new dates
          // If displaying upcoming games, games are displayed started from soonest to latest
          // If displaying completed games, games are displayed from most recently played to earliest played
          if (
            (completed === false && currentDate.isBefore(moment(game.datetime), 'day'))
            || (completed === true && currentDate.isAfter(moment(game.datetime), 'day'))
            ) {
            currentDate = moment(game.datetime);
            newDate = true;
          }
          
          return (
            <div key={`${game.teamone}-vs-${game.teamtwo}`}>
              {newDate === true ? <h3 className='game-date'>{currentDate.utc().format('MMMM Do, YYYY')}</h3> : <></>}
              {/* if newDate was true in previous line, set it back to false */}
              {newDate === true ? newDate = false : null}
              <GameCard
                teamone={game.teamone}
                teamtwo={game.teamtwo}
                teamonescore={game.teamonescore}
                teamtwoscore={game.teamtwoscore}
                datetime={game.datetime}
                division={game.division}
                gym={game.gym}
                completed={completed} />
            </div>
          )
        }
      })}
  </>
  );
}

export default GameDisplay;