import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../../../firebase";
import { useNavigate, Link } from "react-router-dom";
// Styling
import '../../../styles/dashboard.scss';

function Dashboard() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    // Check if user is authenticated. If not, return user to login page
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading]);

    return (
        <div className="dashboard-page">
            <h1 className="page-title">Dashboard</h1>
            <button className="logout-button" onClick={() => logout()}>Log out</button>
            
            <div className="dashboard-sections">
                <div className="games-teams-section">
                    <h2>Games & Teams</h2>
                    <p><Link className="admin-link" to='/manage-divisions'>Manage Divisions</Link></p>
                    <p><Link className="admin-link" to='/manage-gyms'>Manage Gyms</Link></p>
                    <p><Link className="admin-link" to='/manage-teams'>Manage Teams</Link></p>
                    <p><Link className="admin-link" to='/manage-games'>Manage Games</Link></p>
                </div>
                
                <div className="registration-section">
                    <h2>Registration</h2>
                    <p><Link className="admin-link" to='/manage-seasons'>Manage Seasons</Link></p>
                    <p><Link className="admin-link" to='/manage-registrations'>Manage Registrations</Link></p>
                    <p><Link className="admin-link" to='/view-waivers'>View Waiver Submissions</Link></p>
                </div>

                <div className="other-section">
                    <h2>Other</h2>
                    <p><Link className="admin-link" to='/manage-announcements'>Manage Announcements</Link></p>
                    <p><Link className="admin-link" to='/manage-images'>Manage Images</Link></p>
                    <p><Link className="admin-link" to='/manage-potw'>Manage POTW</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;