import React, { useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
// Firebase
import { getStorage, ref, deleteObject } from 'firebase/storage';
// Styling
import '../../styles/imageModal.scss';

function ImageModal(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const deleteImage = async () => {
    const storage = getStorage();
    const imageRef = ref(storage, `${props.refLocation}/${props.filename}`);

    deleteObject(imageRef).then(() => {
      alert("Image has been deleted successfully.");
      handleClose();
      navigate(0);
    }).catch((error) => {
      alert("Could not delete image. ");
    });
  }

  return (
    <>
      <div className='image-container'>
        <img src={props.imageURL} alt={props.imageURL} onClick={() => {handleOpen()}}/>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='image-dialog'
        aria-describedby='image-dialog'
      >
        <DialogContent>
          <img src={props.imageURL} alt={props.imageURL}/>
        </DialogContent>

        {props.admin ?
          <DialogActions>
            <button className='image-delete-button' onClick={deleteImage}>Delete</button>
          </DialogActions>
          :
          <></>
        }
        
      </Dialog>
    </>
  );
}

export default ImageModal;