import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Link } from 'react-router-dom';
import './../../styles/footer.scss';
import { ReactComponent as FacebookLogo } from './../../assets/icons/facebook.svg';
import { ReactComponent as InstagramLogo } from './../../assets/icons/instagram.svg';

function Footer () {
    const [user, loading, error] = useAuthState(auth);

    return (
        <div className="footer">
            <div className="link-container">
                <div className='social-media-links'>
                    <a className='facebook-link' href='https://www.facebook.com/PSAEDM' rel='noreferrer' target='_blank'><FacebookLogo /></a>
                    <a className='instagram-link' href='https://www.instagram.com/psaedm/' rel='noreferrer' target='_blank'><InstagramLogo /></a>
                </div>
                <div className='psa-links'>
                    <Link to='/about'>About</Link>
                    {!user ? <Link to='/login'>Login</Link> : <Link to='/dashboard'>Dashboard</Link>}
                </div>
            </div>
        </div>
    )
}

export default Footer;